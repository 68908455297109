import { Box, Button, ImageListItem, MenuItem, TextField } from '@mui/material';
import { Formik } from 'formik';
import * as yup from 'yup';
import useMediaQuery from '@mui/material/useMediaQuery';
import Header from '../../components/Header';
import { useState, useEffect, useContext } from 'react';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import english from '../../locales/english.json';
import croatian from '../../locales/croatian.json';
import {
  AddNewAdministrator,
  EditAdministrator,
  UploadImage,
} from '../../services/AdministratorService';
import { GetCustomers } from '../../services/CustomerService';
import LanguageContext from '../../LanguageContext';
import AdministratorContext from '../../AdministratorContext';

const AdministratorForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isNonMobile = useMediaQuery('(min-width:600px)');
  const { language } = useContext(LanguageContext);
  const { administratorInfo, setAdministratorInfo } =
    useContext(AdministratorContext);
  const defaultLanguage = language === 0 ? croatian : english;
  const administratorToEdit = location.state || {};

  useEffect(() => {
    if (administratorInfo.role !== 'administrator' && location.state === null) {
      navigate('/');
    }
  }, [administratorInfo.role, location.state, navigate]);

  const [name, setName] = useState('');
  const [file, setFile] = useState({});
  const [allCustomers, setAllCustomers] = useState([]);
  const [role, setRole] = useState(
    !!administratorToEdit.role ? administratorToEdit.role : ''
  );
  const [customer, setCustomer] = useState(
    !!administratorToEdit.customer_id ? administratorToEdit.customer_id : 1
  );
  const [imageName, setImageName] = useState(
    !!administratorToEdit.picture ? administratorToEdit.picture : ''
  );
  const [errors, setErrors] = useState([]);

  const checkoutSchema = yup.object().shape({
    firstName: yup.string().required('required'),
    lastName: yup.string().required('required'),
    email: yup.string().email('invalid email').required('required'),
    role: yup.string().required('required'),
    password: yup.string().required('required'),
    confirmpassword: yup.string().required('required'),
  });

  const initialValues = {
    firstName: !!administratorToEdit.first_name
      ? administratorToEdit.first_name
      : '',
    lastName: !!administratorToEdit.last_name
      ? administratorToEdit.last_name
      : '',
    email: !!administratorToEdit.email ? administratorToEdit.email : '',
    role: !!administratorToEdit.role ? administratorToEdit.role : '',
    picture: !!administratorToEdit.picture ? administratorToEdit.picture : '',
    customer: !!administratorToEdit.customer_id
      ? administratorToEdit.customer_id
      : 1,
    password: '',
    confirmpassword: '',
  };

  const onChangeImg = (event) => {
    setImageName(event.target.files[0]['name']);
    setName(URL.createObjectURL(event.target.files[0]));
    setFile(event.target.files[0]);
  };

  const handleRoleChange = (event) => {
    setRole(event.target.value);
  };

  const handleCustomerChange = (event) => {
    setCustomer(event.target.value);
  };

  const handleFormSubmit = (values) => {
    console.log(values);
  };

  function isEmailValid(email) {
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailRegex.test(email);
  }

  useEffect(() => {
    if (errors.length > 0) {
      let sviErrori = errors.join(', ');

      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: 'All fields are necessary',
        html: sviErrori,
        showConfirmButton: false,
        timer: 4000,
      });
    }
  }, [errors, defaultLanguage]);

  useEffect(() => {
    const getCustomers = async () => {
      const response = await GetCustomers();

      if (response.data.status === 200) {
        const customers = response.data.customers.map((item) => {
          item['id'] = parseInt(item['id']);
          return item;
        });
        setAllCustomers(customers);
      }
    };

    getCustomers();
  }, []);

  const handleSubmitForm = async (values) => {
    values.preventDefault();

    const valuesApproved = checkFormIntegrity(values.target);

    if (valuesApproved.formErrors.length === 0) {
      if (imageName !== '') {
        const responseUpload = await UploadImage(file);
        console.log(responseUpload);
      }

      valuesApproved.allInputs.picture = imageName;
      valuesApproved.allInputs.customer = customer;

      const response = await AddNewAdministrator(
        JSON.stringify(valuesApproved.allInputs)
      );

      if (response.status === 200) {
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: defaultLanguage['administrator-success-add'],
          html: response.info,
          showConfirmButton: false,
          timer: 1500,
        });

        navigate('/administrators');
      } else {
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: defaultLanguage['administrator-email-exist'],
          html: response.info,
          showConfirmButton: false,
          timer: 4000,
        });
      }
    } else {
      return setErrors(valuesApproved.formErrors);
    }
  };

  const handleEditForm = async (administratorValues) => {
    const filteredAdministratorValues = Object.entries(
      administratorValues
    ).reduce((acc, [key, value]) => {
      if (value !== undefined && value !== '') {
        acc[key] = value;
      }
      return acc;
    }, {});

    const valuesApproved = checkFormIntegrity(
      filteredAdministratorValues,
      true
    );

    if (valuesApproved.formErrors.length === 0) {
      if (name) {
        const responseUpload = await UploadImage(file);

        if (responseUpload.status === 200) {
          valuesApproved['allInputs'].picture = imageName;
        }
      }

      valuesApproved['allInputs'].id = administratorToEdit.id;
      valuesApproved['allInputs'].role = role;
      valuesApproved['allInputs'].customer = customer;

      const responseEdit = await EditAdministrator(valuesApproved['allInputs']);

      if (responseEdit.status === 200) {
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: defaultLanguage['administrator-success-edit'],
          html: responseEdit.data.info,
          showConfirmButton: false,
          timer: 1500,
        });

        const loggedInAdministrator = JSON.parse(
          localStorage.getItem('administratorInfo')
        );
        if (administratorToEdit.id === loggedInAdministrator.id) {
          localStorage.setItem(
            'administratorInfo',
            JSON.stringify(valuesApproved['allInputs'])
          );
          setAdministratorInfo(valuesApproved['allInputs']);

          navigate('/administrators');
          window.location.reload();
        }

        navigate('/administrators');
      }
    } else {
      return setErrors(valuesApproved.formErrors);
    }
  };

  const checkFormIntegrity = (values, isEdit = false) => {
    let formErrors = [];

    let allInputs = {};

    if (isEdit) {
      Object.keys(values).forEach((item) => {
        const singleItem = values[item];

        switch (item) {
          case 'firstName':
            if (singleItem === '') {
              formErrors.push('First name is a required field');
            } else {
              allInputs.firstName = singleItem;
            }

            break;

          case 'lastName':
            if (singleItem === '') {
              formErrors.push('Last name is a required field');
            } else {
              allInputs.lastName = singleItem;
            }

            break;

          case 'email':
            if (singleItem === '') {
              formErrors.push('Email is a required field');
            } else if (!isEmailValid(singleItem)) {
              formErrors.push('Email is in incorrect form');
            } else {
              allInputs.email = singleItem;
            }

            break;

          case 'role':
            if (singleItem === '') {
              formErrors.push('Role is a required field');
            } else {
              allInputs.role = singleItem;
            }

            break;

          case 'password':
            if (singleItem === '') {
              formErrors.push('Password is a required field');
            } else {
              allInputs.password = singleItem;
            }

            break;

          case 'confirmpassword':
            if (singleItem === '') {
              formErrors.push('Confirm password is a required field');
            } else {
              allInputs.confirmpassword = singleItem;
            }

            break;

          default:
            break;
        }
      });
    } else {
      Object.keys(values).forEach((item) => {
        const singleItem = values[item];

        if (singleItem.tagName === 'INPUT') {
          switch (singleItem.name) {
            case 'firstName':
              if (singleItem.defaultValue === '') {
                formErrors.push('First name is a required field');
              } else {
                allInputs.firstName = singleItem.defaultValue;
              }

              break;

            case 'lastName':
              if (singleItem.defaultValue === '') {
                formErrors.push('Last name is a required field');
              } else {
                allInputs.lastName = singleItem.defaultValue;
              }

              break;

            case 'email':
              if (singleItem.defaultValue === '') {
                formErrors.push('Email is a required field');
              } else if (!isEmailValid(singleItem.defaultValue)) {
                formErrors.push('Email is in incorrect form');
              } else {
                allInputs.email = singleItem.defaultValue;
              }

              break;

            case 'role':
              if (singleItem.defaultValue === '') {
                formErrors.push('Role is a required field');
              } else {
                allInputs.role = singleItem.defaultValue;
              }

              break;

            case 'password':
              if (singleItem.defaultValue === '') {
                formErrors.push('Password is a required field');
              } else {
                allInputs.password = singleItem.defaultValue;
              }

              break;

            case 'confirmpassword':
              if (singleItem.defaultValue === '') {
                formErrors.push('Confirm password is a required field');
              } else {
                allInputs.confirmpassword = singleItem.defaultValue;
              }

              break;

            default:
              break;
          }
        }
      });
    }

    const doPasswordsMatch = allInputs.password === allInputs.confirmpassword;

    if (!doPasswordsMatch) {
      formErrors.push(defaultLanguage['login-form-passwords-match']);
    } else {
      delete allInputs.confirmpassword;
    }

    return { formErrors, allInputs };
  };

  const headerForm =
    Object.keys(administratorToEdit).length === 0 ? (
      <Header
        title={defaultLanguage['form-new-title']}
        subtitle={defaultLanguage['form-new-subtitle']}
      />
    ) : (
      <Header
        title={defaultLanguage['form-edit-title']}
        subtitle={defaultLanguage['form-edit-subtitle']}
      />
    );

  return (
    <>
      <Box m='20px'>
        {headerForm}

        <Formik
          onSubmit={handleFormSubmit}
          initialValues={initialValues}
          validationSchema={checkoutSchema}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmitForm}>
              <Box
                display='grid'
                gap='30px'
                gridTemplateColumns='repeat(4, minmax(0, 1fr))'
                sx={{
                  '& > div': { gridColumn: isNonMobile ? undefined : 'span 4' },
                }}
              >
                <TextField
                  fullWidth
                  variant='filled'
                  type='text'
                  label={defaultLanguage['administrators-table-first-name']}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.firstName}
                  name='firstName'
                  error={!!touched.firstName && !!errors.firstName}
                  helperText={touched.firstName && errors.firstName}
                  sx={{ gridColumn: 'span 2' }}
                />
                <TextField
                  fullWidth
                  variant='filled'
                  type='text'
                  label={defaultLanguage['administrators-table-last-name']}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.lastName}
                  name='lastName'
                  error={!!touched.lastName && !!errors.lastName}
                  helperText={touched.lastName && errors.lastName}
                  sx={{ gridColumn: 'span 2' }}
                />
                <TextField
                  fullWidth
                  variant='filled'
                  type='text'
                  label={defaultLanguage['administrators-table-email']}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.email}
                  name='email'
                  error={!!touched.email && !!errors.email}
                  helperText={touched.email && errors.email}
                  sx={{ gridColumn: 'span 2' }}
                />
                <TextField
                  fullWidth
                  variant='filled'
                  type='password'
                  label={defaultLanguage['administrators-table-password']}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.password}
                  name='password'
                  error={
                    !!!location.state && !!touched.password && !!errors.password
                  }
                  helperText={
                    !!!location.state && touched.password && errors.password
                  }
                  sx={{ gridColumn: 'span 1' }}
                />
                <TextField
                  fullWidth
                  variant='filled'
                  type='password'
                  label={
                    defaultLanguage['administrators-table-confirm-password']
                  }
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.confirmpassword}
                  name='confirmpassword'
                  error={
                    !!!location.state &&
                    !!touched.confirmpassword &&
                    !!errors.confirmpassword
                  }
                  helperText={
                    !!!location.state &&
                    touched.confirmpassword &&
                    errors.confirmpassword
                  }
                  sx={{ gridColumn: 'span 1' }}
                />
                {administratorInfo.role === 'administrator' && (
                  <TextField
                    select
                    label={defaultLanguage['administrators-table-role']}
                    name='role'
                    helperText={defaultLanguage['administrators-table-role']}
                    variant='filled'
                    value={role || values.role}
                    onChange={handleRoleChange}
                  >
                    <MenuItem key='administrator' value='administrator'>
                      Administrator
                    </MenuItem>
                    <MenuItem key='user' value='user'>
                      Korisnik
                    </MenuItem>
                  </TextField>
                )}

                {allCustomers.length &&
                administratorInfo.role === 'administrator' ? (
                  <TextField
                    select
                    label={defaultLanguage['administrators-table-customer']}
                    name='customer'
                    helperText={
                      defaultLanguage['administrators-table-customer']
                    }
                    variant='filled'
                    value={customer || values.customer}
                    onChange={handleCustomerChange}
                  >
                    {allCustomers.map((customer) => (
                      <MenuItem key={customer.id} value={customer.id}>
                        {customer.name}
                      </MenuItem>
                    ))}
                  </TextField>
                ) : (
                  <div></div>
                )}
              </Box>

              {name || administratorToEdit.picture ? (
                <Box display='flex' width={isNonMobile ? '50%' : '100%'}>
                  <Box style={{ padding: '.5rem', border: '1px solid' }}>
                    <ImageListItem>
                      <img
                        src={
                          name
                            ? name
                            : administratorToEdit.picture
                            ? `${administratorToEdit.picture}`
                            : ''
                        }
                        alt={`${administratorToEdit.picture || imageName}`}
                        loading='lazy'
                      />
                    </ImageListItem>
                  </Box>
                </Box>
              ) : null}

              <Box
                display='flex'
                flexDirection='column'
                gap='10px'
                width={isNonMobile ? '20%' : '100%'}
              >
                <Button
                  variant='contained'
                  component='label'
                  color='secondary'
                  style={{ marginTop: '1rem' }}
                >
                  {defaultLanguage['form-button-select-image']}
                  <input
                    accept='image/*'
                    type='file'
                    onChange={onChangeImg}
                    hidden
                  />
                </Button>
                {Object.keys(administratorToEdit).length === 0 ? (
                  <Button type='submit' color='secondary' variant='contained'>
                    {defaultLanguage['form-button-create-administrator']}
                  </Button>
                ) : (
                  <Button
                    onClick={() => handleEditForm(values)}
                    color='secondary'
                    variant='contained'
                  >
                    {defaultLanguage['form-button-update-administrator']}
                  </Button>
                )}
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    </>
  );
};

export default AdministratorForm;
