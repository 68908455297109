import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, IconButton, MenuItem, Select } from '@mui/material';
import LanguageContext from '../../LanguageContext';
import AdministratorContext from '../../AdministratorContext';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import PublicIcon from '@mui/icons-material/Public';
import english from '../../locales/english.json';
import croatian from '../../locales/croatian.json';
import { GetAdministrators } from '../../services/AdministratorService';

const loggedInAdministrator = JSON.parse(
  localStorage.getItem('administratorInfo')
);

const Topbar = ({ loginScreen }) => {
  const navigate = useNavigate();
  let profileSelectValue = 0;
  const { setAdministratorInfo } = useContext(AdministratorContext);
  const { language, setLanguage } = useContext(LanguageContext);
  const defaultLanguage = language === 0 ? croatian : english;
  const [administrators, setAdministrators] = useState([]);

  const handleProfileSelectChange = (event) => {
    profileSelectValue = event.target.value;

    if (event.target.value === 1) {
      EditAdministrator();
    }

    if (event.target.value === 2) {
      localStorage.clear();
      window.location.reload();
    }
  };

  const handleLanguageSelectChange = (event) => {
    setLanguage(event.target.value);
    localStorage.setItem('language', event.target.value);
  };

  const findAdministratorsQuery = async () => {
    const findAdministrators = async () => {
      const response = await GetAdministrators();

      if (response.data.status === 404) {
        setAdministrators([]);
      } else {
        const administrators = response.data.administrators.map((item) => {
          item['id'] = parseInt(item['id']);
          item['customer_id'] = parseInt(item['customer_id']);
          return item;
        });

        const selectedAdministrator = administrators.find(
          (administrator) => administrator.id === loggedInAdministrator['id']
        );

        setAdministratorInfo(selectedAdministrator);
        setAdministrators(administrators);
      }
    };
    findAdministrators();
  };

  const EditAdministrator = async () => {
    await findAdministratorsQuery();

    const loggedInAdministrator = JSON.parse(
      localStorage.getItem('administratorInfo')
    );
    const selectedAdministrator = administrators.find(
      (administrator) => administrator.id === loggedInAdministrator['id']
    );

    navigate('/administrator-form', { state: selectedAdministrator });
  };

  useEffect(() => {
    if (loggedInAdministrator && !loginScreen) {
      findAdministratorsQuery();
    }
  }, []);

  return (
    <Box display='flex' justifyContent='flex-end' p={2}>
      <Box display='flex' style={{ position: 'fixed' }}>
        <IconButton>
          <PublicIcon />
          <Select
            value={language}
            onChange={handleLanguageSelectChange}
            sx={{
              position: 'absolute',
              opacity: 0,
              height: 0,
              width: 1,
              '&:focus': {
                opacity: 1,
                height: 'auto',
                width: 'auto',
              },
            }}
          >
            <MenuItem value={0}>
              {defaultLanguage['topbar-settings-language-croatian']}
            </MenuItem>
            <MenuItem value={1}>
              {defaultLanguage['topbar-settings-language-english']}
            </MenuItem>
          </Select>
        </IconButton>
        {/* <IconButton onClick={colorMode.toggleColorMode}>
          {theme.palette.mode === "dark" ? (
            <LightModeOutlinedIcon />
            ) : (
            <DarkModeOutlinedIcon />
          )}
        </IconButton> */}
        {!loginScreen ? (
          <IconButton>
            <PersonOutlinedIcon />
            <Select
              value={profileSelectValue}
              onChange={handleProfileSelectChange}
              sx={{
                position: 'absolute',
                opacity: 0,
                height: 0,
                width: 1,
                '&:focus': {
                  opacity: 1,
                  height: 'auto',
                  width: 'auto',
                },
              }}
            >
              <MenuItem value={0} style={{ display: 'none' }}></MenuItem>
              <MenuItem value={1}>
                {defaultLanguage['topbar-profile-my-profile']}
              </MenuItem>
              <MenuItem value={2}>
                {defaultLanguage['topbar-profile-logout']}
              </MenuItem>
            </Select>
          </IconButton>
        ) : null}
      </Box>
    </Box>
  );
};

export default Topbar;
