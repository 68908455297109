import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { ImageListItem } from '@mui/material';

export function SortableGallery({ isLoading, image, deleteImage }) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: image });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <ImageListItem
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
    >
      <span
        className={!isLoading ? 'delete' : 'delete delete-loading'}
        onClick={() => (!isLoading ? deleteImage(image) : null)}
      >
        &times;
      </span>
      <img src={image} srcSet={image} alt={image} loading='lazy' />
    </ImageListItem>
  );
}
