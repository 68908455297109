import { Box } from '@mui/material';
import Header from '../../components/Header';
import english from '../../locales/english.json';
import croatian from '../../locales/croatian.json';

const defaultLanguage = croatian;

const HomePage = () => {
  return (
    <Box m='20px'>
      <Box display='flex' justifyContent='space-between' alignItems='center'>
        <Header
          title={defaultLanguage['home-title']}
          subtitle={defaultLanguage['home-subtitle']}
        />
      </Box>
    </Box>
  );
};

export default HomePage;
