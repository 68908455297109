import { Box, useTheme, Avatar, Button } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { tokens } from '../../theme';
import LanguageContext from '../../LanguageContext';
import Header from '../../components/Header';
import { useState, useEffect, useContext } from 'react';
import { GetEvents, DeleteEvents } from '../../services/EventsService';
import { useNavigate } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import Swal from 'sweetalert2';
import english from '../../locales/english.json';
import croatian from '../../locales/croatian.json';

const targetAPI = process.env.REACT_APP_API_URL;

const Events = () => {
  const navigate = useNavigate();
  const isNonMobile = useMediaQuery('(min-width:600px)');
  const { language } = useContext(LanguageContext);
  const defaultLanguage = language === 0 ? croatian : english;

  const [events, setEvents] = useState([]);
  const [isEditDisabled, setIsEditDisabled] = useState(true);
  const [isDeleteDisabled, setIsDeleteDisabled] = useState(true);
  const [selectedRow, setSelectedRow] = useState([]);
  const [isDeleted, setIsDeleted] = useState(false);
  const [eventForEdit, setEventForEdit] = useState({});

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const columns = [
    { field: 'id', headerName: 'ID' },
    {
      field: 'title',
      headerName: defaultLanguage['events-table-title'],
      flex: 1,
    },
    {
      field: 'subtitle',
      headerName: defaultLanguage['events-table-subtitle'],
      flex: 1,
    },
    {
      field: 'content',
      headerName: defaultLanguage['events-table-content'],
      flex: 1,
    },
    {
      field: 'url',
      headerName: defaultLanguage['events-table-url'],
      flex: 1,
    },
    {
      field: 'url_name',
      headerName: defaultLanguage['events-table-url-name'],
      flex: 1,
    },
    {
      field: 'start',
      headerName: defaultLanguage['events-table-start'],
      flex: 1,
    },
    {
      field: 'end',
      headerName: defaultLanguage['events-table-end'],
      flex: 1,
    },
    {
      field: 'location',
      headerName: defaultLanguage['events-table-location'],
      flex: 1,
    },
    {
      field: 'status',
      headerName: defaultLanguage['events-table-status'],
      flex: 1,
    },
    {
      field: 'picture',
      headerName: defaultLanguage['events-table-picture'],
      headerAlign: 'center',
      renderCell: ({ row: { picture } }) => {
        return (
          <>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
              }}
            >
              <Avatar src={`${picture}`} />
            </div>
          </>
        );
      },
    },
    {
      field: 'date_created',
      headerName: defaultLanguage['events-table-date-created'],
      flex: 1,
    },
    {
      field: 'date_updated',
      headerName: defaultLanguage['events-table-date-updated'],
      flex: 1,
    },
  ];

  const findEventsQuery = () => {
    const findEvents = async () => {
      const response = await GetEvents();

      if (response.data.status === 404) {
        setEvents([]);
      } else {
        const events = response.data.events.map((event) => {
          event['id'] = parseInt(event['id']);
          event['status'] = parseInt(event['status']);
          if (!!event['picture']) {
            event['picture'] = event['picture'].startsWith('http')
              ? event['picture']
              : `${targetAPI}/${event.picture}`;
          }

          event['start'] = event['start'].replace('T', ' ');
          event['end'] = event['end'].replace('T', ' ');

          return event;
        });

        setEvents(events);
      }
    };
    findEvents();
  };

  useEffect(() => {
    findEventsQuery();
  }, []);

  useEffect(() => {
    if (isDeleted) {
      findEventsQuery();
      setIsDeleted(false);
    }
  }, [isDeleted]);

  const checkButtons = (data) => {
    setSelectedRow(data);

    let isEditBtnDisabled = false;
    let isDeleteBtnDisabled = false;

    if (data.length === 1) {
      isEditBtnDisabled = false;
    } else {
      isEditBtnDisabled = true;
    }

    if (data.length > 0) {
      isDeleteBtnDisabled = false;
    } else {
      isDeleteBtnDisabled = true;
    }

    if (!isEditBtnDisabled) {
      collectEventDataForEdit(data);
    }

    setIsEditDisabled(isEditBtnDisabled);
    setIsDeleteDisabled(isDeleteBtnDisabled);
  };

  const collectEventDataForEdit = (rowId) => {
    const rowIdTest = parseInt(rowId[0]);

    const selectedEvent = events.find((event) => event.id === rowIdTest);

    setEventForEdit(selectedEvent);
  };

  const DeleteEventQuery = async () => {
    await Swal.fire({
      title: defaultLanguage['delete-event-title'],
      text: defaultLanguage['delete-event-text'],
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: defaultLanguage['confirm-button-text'],
      cancelButtonText: defaultLanguage['cancel-button-text'],
    }).then(async (result) => {
      if (result.isConfirmed) {
        const responseDelete = await DeleteEvents(selectedRow);

        if (responseDelete.status === 200) {
          setIsDeleted(true);
          Swal.fire(defaultLanguage['event-success-delete']);
        }
      }
    });
  };

  return (
    <Box m='20px'>
      <Header
        title={defaultLanguage['events-title']}
        subtitle={defaultLanguage['events-subtitle']}
      />

      <div
        style={
          !isNonMobile ? { display: 'flex', flexDirection: 'column' } : null
        }
      >
        <Button
          onClick={() => navigate('/events-form')}
          variant='contained'
          color='secondary'
        >
          {defaultLanguage['events-button-add']}
        </Button>
        <Button
          onClick={() => navigate('/events-form', { state: eventForEdit })}
          variant='contained'
          color='warning'
          disabled={isEditDisabled}
          style={isNonMobile ? { margin: '0 .5rem' } : { margin: '.5rem 0' }}
        >
          {defaultLanguage['events-button-edit']}
        </Button>
        <Button
          onClick={() => DeleteEventQuery()}
          variant='contained'
          color='error'
          disabled={isDeleteDisabled}
        >
          {defaultLanguage['events-button-delete']}
        </Button>
      </div>

      <Box
        m='40px 0 0 0'
        height='75vh'
        sx={{
          '& .MuiDataGrid-root': {
            border: 'none',
          },
          '& .MuiDataGrid-cell': {
            borderBottom: 'none',
          },
          '& .name-column--cell': {
            color: colors.greenAccent[300],
          },
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: colors.blueAccent[700],
            borderBottom: 'none',
          },
          '& .MuiDataGrid-virtualScroller': {
            backgroundColor: colors.primary[400],
          },
          '& .MuiDataGrid-footerContainer': {
            borderTop: 'none',
            backgroundColor: colors.blueAccent[700],
          },
          '& .MuiCheckbox-root': {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <DataGrid
          onSelectionModelChange={checkButtons}
          checkboxSelection
          rows={events.status === 404 ? [] : events}
          columns={columns}
        />
      </Box>
    </Box>
  );
};

export default Events;
