import { Box, Button, ImageListItem, MenuItem, TextField } from '@mui/material';
import { Formik } from 'formik';
import * as yup from 'yup';
import Header from '../../components/Header';
import { useState, useEffect, useContext } from 'react';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import english from '../../locales/english.json';
import croatian from '../../locales/croatian.json';
import {
  AddNewPost,
  EditPost,
  UploadImage,
  UploadDocuments,
  DeleteUnusedFilesFromServer,
} from '../../services/PostService';
import LanguageContext from '../../LanguageContext';
import AdministratorContext from '../../AdministratorContext';
import { GetCategories } from '../../services/CategoriesService';
import { GetAdministrators } from '../../services/AdministratorService';
import { GetGalleries } from '../../services/GalleriesService';
import { GetCustomers } from '../../services/CustomerService';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { LoadingButton } from '@mui/lab';

const PostForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { administratorInfo } = useContext(AdministratorContext);
  const { language } = useContext(LanguageContext);
  const defaultLanguage = language === 0 ? croatian : english;
  const postToEdit = location.state || {};

  const [name, setName] = useState('');
  const [file, setFile] = useState({});
  const [imageName, setImageName] = useState(!!file.name ? file.name : '');
  const [content, setContent] = useState(
    Object.keys(postToEdit).length ? postToEdit.content : ''
  );
  const [category, setCategory] = useState(
    !!postToEdit.category_id ? postToEdit.category_id : ''
  );
  const [gallery, setGallery] = useState(
    !!postToEdit.gallery_id ? postToEdit.gallery_id : ''
  );
  const [author, setAuthor] = useState(
    !!postToEdit.author_id ? postToEdit.author_id : ''
  );
  const [customer, setCustomer] = useState(
    !!postToEdit.customer_id
      ? postToEdit.customer_id
      : !!administratorInfo.customer_id
      ? administratorInfo.customer_id
      : 1
  );
  const [status, setStatus] = useState(
    !!postToEdit.status ? postToEdit.status : '0'
  );
  const [allCategories, setAllCategories] = useState([]);
  const [allGalleries, setAllGalleries] = useState([]);
  const [allAdministrators, setAllAdministrators] = useState([]);
  const [allCustomers, setAllCustomers] = useState([]);
  const [errors, setErrors] = useState([]);
  const [isPictureDefaultLogo, setIsPictureDefaultLogo] = useState(
    Object.keys(postToEdit).length
      ? postToEdit.picture.endsWith('default_tpbj_logo.png')
      : false
  );
  const [isLoading, setIsLoading] = useState(false);
  const [documentsFile, setDocumentsFile] = useState([]);
  const [documentsName, setDocumentsName] = useState(
    !!postToEdit.documents ? JSON.parse(postToEdit.documents) : []
  );

  const checkoutSchema = yup.object().shape({
    title: yup.string().required('required'),
    author: yup.number().required('required'),
    category: yup.number().required('required'),
    content: yup.string().required('required'),
  });

  const initialValues = {
    title: !!postToEdit.title ? postToEdit.title : '',
    author: !!postToEdit.author_id ? postToEdit.author_id : '',
    category: !!postToEdit.category_id ? postToEdit.category_id : '',
    content: !!postToEdit.content ? postToEdit.content : '',
  };

  const onChangeImg = (event) => {
    setImageName(event.target.files[0]['name']);
    setName(URL.createObjectURL(event.target.files[0]));
    setFile(event.target.files[0]);
    setIsPictureDefaultLogo(false);
  };

  const onChangeDocument = (event) => {
    const selectedFiles = event.target.files;
    const filesArray = Array.from(selectedFiles);

    const fileNames = filesArray.map((file) => file.name);
    setDocumentsName([...documentsName, ...fileNames]);

    setDocumentsFile([...documentsFile, ...filesArray]);
  };

  const deleteDocument = (index) => {
    const newDocumentsList = documentsName.filter(
      (document) => document !== documentsName[index]
    );

    setDocumentsName(newDocumentsList);
  };

  const removeGallery = () => {
    setGallery('');
  };

  const removePhoto = () => {
    postToEdit.picture = '';
    setImageName('');
    setName('');
    setFile({});
  };

  const disableDeleteImageButton = () => {
    let isButtonDisabled = true;

    if (Object.keys(postToEdit).length) {
      if (postToEdit.picture !== '') {
        isButtonDisabled = false;
      }
    }

    if (Object.keys(file).length || imageName !== '' || name !== '') {
      isButtonDisabled = false;
    }

    return isButtonDisabled;
  };

  const handleCategoryChange = (event) => {
    setCategory(event.target.value);
  };

  const handleGalleryChange = (event) => {
    setGallery(event.target.value);
  };

  const handleAuthorChange = (event) => {
    setAuthor(event.target.value);
  };

  const handleCustomerChange = (event) => {
    setCustomer(event.target.value);
  };

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };

  useEffect(() => {
    if (errors.length > 0) {
      let sviErrori = errors.join(', ');

      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: defaultLanguage['login-form-input-validation'],
        html: sviErrori,
        showConfirmButton: false,
        timer: 4000,
      });
    }
  }, [errors, defaultLanguage]);

  useEffect(() => {
    const getAdministrators = async () => {
      const response = await GetAdministrators();

      if (response.data.status === 200) {
        const administrators = response.data.administrators.map((item) => {
          item['id'] = parseInt(item['id']);
          return item;
        });
        setAllAdministrators(administrators);
      }
    };

    getAdministrators();
  }, []);

  useEffect(() => {
    const getCustomers = async () => {
      const response = await GetCustomers();

      if (response.data.status === 200) {
        const customers = response.data.customers.map((customer) => {
          customer['id'] = parseInt(customer['id']);
          customer['status'] = parseInt(customer['status']);
          return customer;
        });
        setAllCustomers(customers);
      }
    };

    getCustomers();
  }, []);

  useEffect(() => {
    const getCategories = async () => {
      const response = await GetCategories();

      if (response.data.status === 200) {
        const categories = response.data.categories.map((item) => {
          item['id'] = parseInt(item['id']);
          return item;
        });
        setAllCategories(categories);
      }
    };

    getCategories();
  }, []);

  useEffect(() => {
    const getGalleries = async () => {
      const response = await GetGalleries();

      if (response.data.status === 200) {
        const galleries = response.data.galleries.map((item) => {
          item['id'] = parseInt(item['id']);
          return item;
        });
        setAllGalleries(galleries);
      }
    };

    getGalleries();
  }, []);

  const handleSubmitForm = async (values) => {
    values.preventDefault();

    if (!Object.keys(postToEdit).length) {
      setIsLoading(true);
      values.target['content'] = content;

      const valuesApproved = checkFormIntegrity(values.target);

      if (valuesApproved.formErrors.length === 0) {
        if (gallery !== '') {
          valuesApproved.allInputs.gallery = gallery;
        }

        if (name !== '') {
          const responseUpload = await UploadImage(file);

          if (responseUpload.data.status === 200) {
            valuesApproved.allInputs.picture = file.name;
          }
        } else {
          valuesApproved.allInputs.picture = '';
        }

        if (documentsFile.length > 0) {
          const formData = new FormData();
          documentsFile.forEach((file) => {
            formData.append('documents[]', file);
          });

          const documentResponse = await UploadDocuments(formData);
          if (documentResponse.data.status === 200) {
            valuesApproved.allInputs.documents = JSON.stringify(
              documentResponse.data.names
            );
          }
        }

        valuesApproved.allInputs.customer = customer;

        const response = await AddNewPost(
          JSON.stringify(valuesApproved.allInputs)
        );

        if (response.status === 200) {
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: defaultLanguage['post-success-add'],
            html: response.info,
            showConfirmButton: false,
            timer: 1500,
          });

          navigate('/posts');
        } else {
          Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: defaultLanguage['post-email-exist'],
            html: response.info,
            showConfirmButton: false,
            timer: 4000,
          });
        }
      } else {
        setIsLoading(false);
        return setErrors(valuesApproved.formErrors);
      }

      setIsLoading(false);
    }
  };

  const handleEditForm = async (postValues) => {
    postValues.category = category;
    postValues.author = author;

    postValues['content'] = content;

    const filteredPostValues = Object.entries(postValues).reduce(
      (acc, [key, value]) => {
        if (value !== undefined && value !== '') {
          acc[key] = value;
        }
        return acc;
      },
      {}
    );

    const valuesApproved = checkFormIntegrity(filteredPostValues, true);

    if (valuesApproved.formErrors.length === 0) {
      if (!!valuesApproved['allInputs'].picture || imageName) {
        const responseUpload = await UploadImage(file, postToEdit['id']);

        if (responseUpload.status === 200) {
          valuesApproved['allInputs'].picture = imageName;
        }
      } else if (postToEdit.picture) {
        console.log(postToEdit.picture);
      } else {
        valuesApproved['allInputs'].picture = '';
      }

      valuesApproved['allInputs'].id = postToEdit.id;
      valuesApproved['allInputs'].status = status;
      valuesApproved['allInputs'].gallery = gallery;
      valuesApproved['allInputs'].customer = customer;

      if (documentsFile.length > 0) {
        const formData = new FormData();
        documentsFile.forEach((file) => {
          formData.append('documents[]', file);
        });

        const documentResponse = await UploadDocuments(formData);
        if (documentResponse.data.status === 200) {
          valuesApproved['allInputs'].documents = JSON.stringify(documentsName);
        }
      } else {
        valuesApproved['allInputs'].documents = JSON.stringify(documentsName);
      }

      const responseEdit = await EditPost(valuesApproved['allInputs']);

      if (responseEdit.status === 200) {
        DeleteUnusedFilesFromServer(
          valuesApproved['allInputs'].id,
          documentsName
        );

        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: defaultLanguage['post-success-edit'],
          html: responseEdit.data.info,
          showConfirmButton: false,
          timer: 1500,
        });

        navigate('/posts');
      }
    } else {
      return setErrors(valuesApproved.formErrors);
    }
  };

  const checkFormIntegrity = (values, isEdit = false) => {
    let formErrors = [];

    let allInputs = {};

    if (isEdit) {
      Object.keys(values).forEach((item) => {
        const singleItem = values[item];

        switch (item) {
          case 'title':
            if (singleItem === '') {
              formErrors.push(defaultLanguage['general-form-fields-title']);
            } else {
              allInputs.title = singleItem;
            }

            break;

          case 'author':
            if (singleItem === '') {
              formErrors.push(defaultLanguage['general-form-fields-author']);
            } else {
              allInputs.author = singleItem;
            }

            break;

          case 'status':
            if (singleItem === '') {
              formErrors.push(defaultLanguage['general-form-fields-status']);
            } else {
              allInputs.status = singleItem;
            }

            break;

          case 'category':
            if (singleItem === '') {
              formErrors.push(defaultLanguage['general-form-fields-category']);
            } else {
              allInputs.category = singleItem;
            }

            break;

          default:
            break;
        }
      });
    } else {
      Object.keys(values).forEach((item) => {
        const singleItem = values[item];

        if (
          singleItem.tagName === 'INPUT' ||
          singleItem.tagName === 'TEXTAREA'
        ) {
          switch (singleItem.name) {
            case 'title':
              if (singleItem.defaultValue === '') {
                formErrors.push(defaultLanguage['general-form-fields-title']);
              } else {
                allInputs.title = singleItem.defaultValue;
              }

              break;

            case 'author':
              if (singleItem.defaultValue === '') {
                formErrors.push(defaultLanguage['general-form-fields-author']);
              } else {
                allInputs.author = singleItem.defaultValue;
              }

              break;

            case 'status':
              if (singleItem.defaultValue === '') {
                formErrors.push(defaultLanguage['general-form-fields-status']);
              } else {
                allInputs.status = singleItem.defaultValue;
              }

              break;

            case 'category':
              if (singleItem.defaultValue === '') {
                formErrors.push(
                  defaultLanguage['general-form-fields-category']
                );
              } else {
                allInputs.category = singleItem.defaultValue;
              }

              break;

            default:
              break;
          }
        }
      });
    }

    if (values['content'] === '') {
      formErrors.push(defaultLanguage['general-form-fields-content']);
    } else {
      allInputs.content = values['content'];
    }

    return { formErrors, allInputs };
  };

  const headerForm =
    Object.keys(postToEdit).length === 0 ? (
      <Header
        title={defaultLanguage['post-form-new-title']}
        subtitle={defaultLanguage['post-form-new-subtitle']}
      />
    ) : (
      <Header
        title={defaultLanguage['post-form-edit-title']}
        subtitle={defaultLanguage['post-form-edit-subtitle']}
      />
    );

  return (
    <>
      <div style={{ textAlign: 'center' }}>{headerForm}</div>

      <Box
        display='flex'
        justifyContent='center'
        style={{ paddingBottom: '5rem' }}
      >
        <Box width='75%'>
          <Formik
            initialValues={initialValues}
            validationSchema={checkoutSchema}
          >
            {({ values, errors, touched, handleBlur, handleChange }) => (
              <form onSubmit={handleSubmitForm}>
                <Box display='flex' flexDirection='column' gap='20px'>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='text'
                    label={defaultLanguage['posts-table-title']}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.title}
                    name='title'
                    error={!!touched.title && !!errors.title}
                    helperText={defaultLanguage['posts-table-title']}
                  />

                  <TextField
                    select
                    label={defaultLanguage['posts-table-author']}
                    name='author'
                    helperText={defaultLanguage['posts-table-author']}
                    variant='filled'
                    value={author}
                    onChange={handleAuthorChange}
                  >
                    {allAdministrators.map((administrator) => (
                      <MenuItem key={administrator.id} value={administrator.id}>
                        {`${administrator.first_name} ${administrator.last_name}`}
                      </MenuItem>
                    ))}
                  </TextField>

                  <TextField
                    select
                    label={defaultLanguage['administrators-table-customer']}
                    name='customer'
                    helperText={
                      defaultLanguage['administrators-table-customer']
                    }
                    variant='filled'
                    value={customer}
                    onChange={handleCustomerChange}
                  >
                    {allCustomers.map((customer) => (
                      <MenuItem key={customer.id} value={customer.id}>
                        {`${customer.name}`}
                      </MenuItem>
                    ))}
                  </TextField>

                  <TextField
                    select
                    label='Status'
                    name='status'
                    helperText='Status'
                    variant='filled'
                    value={status}
                    onChange={handleStatusChange}
                  >
                    <MenuItem key={1} value={0}>
                      {defaultLanguage['post-form-status-draft']}
                    </MenuItem>
                    <MenuItem key={2} value={1}>
                      {defaultLanguage['post-form-status-published']}
                    </MenuItem>
                  </TextField>

                  <TextField
                    select
                    label={defaultLanguage['posts-table-category']}
                    name='category'
                    helperText={defaultLanguage['posts-table-category']}
                    variant='filled'
                    value={category}
                    onChange={handleCategoryChange}
                  >
                    {allCategories.map((category) => (
                      <MenuItem key={category.id} value={category.id}>
                        {category.name}
                      </MenuItem>
                    ))}
                  </TextField>

                  <div style={{ display: 'flex', gap: '2rem' }}>
                    <TextField
                      style={{ width: '100%' }}
                      select
                      label={defaultLanguage['posts-table-gallery']}
                      name='gallery'
                      helperText={defaultLanguage['posts-table-gallery']}
                      variant='filled'
                      value={gallery}
                      onChange={handleGalleryChange}
                    >
                      {allGalleries.map((gallery) => (
                        <MenuItem key={gallery.id} value={gallery.id}>
                          {gallery.name}
                        </MenuItem>
                      ))}
                    </TextField>
                    <Button
                      style={{ height: '50px' }}
                      onClick={() => removeGallery()}
                      variant='contained'
                      color='error'
                      disabled={gallery === ''}
                    >
                      {defaultLanguage['posts-button-gallery-remove']}
                    </Button>
                  </div>

                  {documentsName.length ? (
                    <>
                      <h2 style={{ margin: 0 }}>Dokumenti</h2>
                      {documentsName.map((document, index) => (
                        <div className='documents-in-post' key={index}>
                          <div className='document-and-icon'>
                            {document.split('.').pop() === 'docx' ||
                            document.split('.').pop() === 'doc' ? (
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  '/assets/docx_icon.png'
                                }
                                alt='docx doc icon'
                              />
                            ) : (
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  '/assets/pdf_icon.png'
                                }
                                alt='pdf icon'
                              />
                            )}
                            <h3>{document}</h3>
                          </div>
                          <Button
                            onClick={() => deleteDocument(index)}
                            variant='contained'
                            color='error'
                          >
                            {defaultLanguage['posts-button-document-delete']}
                          </Button>
                        </div>
                      ))}
                    </>
                  ) : null}

                  <Button
                    variant='contained'
                    component='label'
                    color='secondary'
                    disabled={isLoading}
                  >
                    {defaultLanguage['posts-button-document-select']}
                    <input
                      accept='.pdf,.doc,.docx'
                      type='file'
                      onChange={onChangeDocument}
                      multiple
                      hidden
                    />
                  </Button>

                  <ReactQuill
                    theme='snow'
                    name='content'
                    value={content}
                    onChange={setContent}
                    style={{ color: '#fff' }}
                  />

                  {name || postToEdit.picture ? (
                    <h2>Vodeća fotografija</h2>
                  ) : null}
                  {name || postToEdit.picture ? (
                    <Box display='flex' justifyContent='center'>
                      <Box
                        style={{
                          padding: '.5rem',
                          border: isPictureDefaultLogo ? null : '1px solid',
                        }}
                      >
                        <ImageListItem>
                          <img
                            src={
                              name
                                ? name
                                : postToEdit.picture
                                ? `${postToEdit.picture}`
                                : ''
                            }
                            alt={`${postToEdit.picture || imageName}`}
                            loading='lazy'
                            style={{
                              width: isPictureDefaultLogo ? '200px' : null,
                            }}
                          />
                        </ImageListItem>
                      </Box>
                    </Box>
                  ) : null}

                  <Box
                    display='flex'
                    justifyContent={'space-between'}
                    gap='10px'
                    alignItems='center'
                  >
                    <Button
                      variant='contained'
                      component='label'
                      color='secondary'
                      disabled={isLoading}
                    >
                      {defaultLanguage['post-form-button-select-image']}
                      <input
                        accept='image/*'
                        type='file'
                        onChange={onChangeImg}
                        hidden
                      />
                    </Button>
                    <Button
                      variant='contained'
                      component='label'
                      color='error'
                      onClick={removePhoto}
                      style={{ wordBreak: 'break-all' }}
                      disabled={disableDeleteImageButton() || isLoading}
                    >
                      {defaultLanguage['post-form-button-delete-image']}
                    </Button>
                  </Box>

                  {isLoading ? (
                    <LoadingButton
                      className='loading-button'
                      loading
                      variant='outlined'
                    >
                      <span>Submit</span>
                    </LoadingButton>
                  ) : Object.keys(postToEdit).length === 0 && !isLoading ? (
                    <Button type='submit' color='secondary' variant='contained'>
                      {defaultLanguage['post-form-button-create']}
                    </Button>
                  ) : (
                    <Button
                      onClick={() => handleEditForm(values)}
                      color='secondary'
                      variant='contained'
                    >
                      {defaultLanguage['post-form-button-update']}
                    </Button>
                  )}
                </Box>
              </form>
            )}
          </Formik>
        </Box>
      </Box>
    </>
  );
};

export default PostForm;
