import { useState, useContext } from 'react';
import { ProSidebar, Menu, MenuItem } from 'react-pro-sidebar';
import { Box, IconButton, Typography, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';
import 'react-pro-sidebar/dist/css/styles.css';
import { tokens } from '../../theme';
import LanguageContext from '../../LanguageContext';
import AdministratorContext from '../../AdministratorContext';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import ClassIcon from '@mui/icons-material/Class';
import CollectionsIcon from '@mui/icons-material/Collections';
import english from '../../locales/english.json';
import croatian from '../../locales/croatian.json';
import { CalendarMonth, SupervisedUserCircle } from '@mui/icons-material';

const websiteUrl = process.env.REACT_APP_WEBSITE_URL;

const Item = ({ title, to, icon, selected, setSelected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <MenuItem
      active={selected === title}
      style={{
        color: colors.grey[100],
      }}
      onClick={() => setSelected(title)}
      icon={icon}
    >
      <Link to={to}>
        <Typography>{title}</Typography>
      </Link>
    </MenuItem>
  );
};

const Sidebar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState('Dashboard');

  const { language } = useContext(LanguageContext);
  const defaultLanguage = language === 0 ? croatian : english;

  const { administratorInfo } = useContext(AdministratorContext);
  const doesAdministratorInfoExistUndefined =
    administratorInfo === undefined ||
    Object.keys(administratorInfo).length < 1;
  const imgName =
    doesAdministratorInfoExistUndefined || administratorInfo['picture'] === ''
      ? administratorInfo.picture === ''
        ? '../../assets/default_tpbj_logo.png'
        : `${administratorInfo.picture}`
      : `${administratorInfo.picture}`;

  return (
    <Box
      sx={{
        '& .pro-sidebar-inner': {
          background: `${colors.primary[400]} !important`,
        },
        '& .pro-icon-wrapper': {
          backgroundColor: 'transparent !important',
        },
        '& .pro-inner-item': {
          padding: '5px 35px 5px 20px !important',
        },
        '& .pro-inner-item:hover': {
          color: '#868dfb !important',
        },
        '& .pro-menu-item.active': {
          color: '#6870fa !important',
        },
      }}
    >
      <ProSidebar collapsed={isCollapsed}>
        <Menu iconShape='square'>
          {/* LOGO AND MENU ICON */}
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
            style={{
              margin: '10px 0 20px 0',
              color: colors.grey[100],
            }}
          >
            {!isCollapsed && (
              <Box
                display='flex'
                justifyContent='space-between'
                alignItems='center'
                ml='15px'
              >
                <Typography variant='h3' color={colors.grey[100]}>
                  Tehnološki Park
                </Typography>
                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                  <MenuOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </MenuItem>

          {!isCollapsed && (
            <Box mb='25px'>
              <Box display='flex' justifyContent='center' alignItems='center'>
                <img
                  alt='profile-user'
                  width='100px'
                  height='100px'
                  src={imgName}
                  style={{
                    cursor: 'pointer',
                    borderRadius: '50%',
                    objectFit: 'cover',
                  }}
                />
              </Box>
              <Box textAlign='center'>
                <Typography
                  variant='h2'
                  color={colors.grey[100]}
                  fontWeight='bold'
                  sx={{ m: '10px 0 0 0' }}
                >
                  {!doesAdministratorInfoExistUndefined
                    ? `${administratorInfo.first_name} ${administratorInfo.last_name}`
                    : `${administratorInfo.first_name} ${administratorInfo.last_name}`}
                </Typography>
                <Typography variant='h5' color={colors.greenAccent[500]}>
                  {!doesAdministratorInfoExistUndefined
                    ? `${administratorInfo.role}`
                    : `${administratorInfo.role}`}
                </Typography>
              </Box>
            </Box>
          )}

          <Box paddingLeft={isCollapsed ? undefined : '10%'}>
            <Item
              title={defaultLanguage['sidebar-list-home']}
              to='/'
              icon={<HomeOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            <Typography
              variant='h6'
              color={colors.grey[300]}
              sx={{ m: '15px 0 5px 20px' }}
            >
              Data
            </Typography>

            {!doesAdministratorInfoExistUndefined &&
            administratorInfo.role === 'administrator' ? (
              <Item
                title={defaultLanguage['sidebar-list-administrators']}
                to='/administrators'
                icon={<PeopleOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
              />
            ) : null}

            <Item
              title={defaultLanguage['sidebar-list-customers']}
              to='/customers'
              icon={<SupervisedUserCircle />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title={defaultLanguage['sidebar-list-categories']}
              to='/categories'
              icon={<ClassIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title={defaultLanguage['sidebar-list-posts']}
              to='/posts'
              icon={<ReceiptOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title={defaultLanguage['sidebar-list-galleries']}
              to='/galleries'
              icon={<CollectionsIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title={defaultLanguage['events-title']}
              to='/events'
              icon={<CalendarMonth />}
              selected={selected}
              setSelected={setSelected}
            />

            {/* <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              Pages
            </Typography>
            <Item
              title="FAQ Page"
              to="/faq"
              icon={<HelpOutlineOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              Website
            </Typography> */}
          </Box>
        </Menu>
        {/* <a
          href={websiteUrl}
          target="_blank"
          rel="noreferrer"
          className="sidebar-tpbj-link"
        >Tehnološki Park</a> */}
      </ProSidebar>
    </Box>
  );
};

export default Sidebar;
