import { Box, Button, ImageListItem, MenuItem, TextField } from '@mui/material';
import { Formik } from 'formik';
import * as yup from 'yup';
import useMediaQuery from '@mui/material/useMediaQuery';
import Header from '../../components/Header';
import { useState, useEffect, useContext } from 'react';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import english from '../../locales/english.json';
import croatian from '../../locales/croatian.json';
import { AddNewCategory, EditCategory } from '../../services/CategoriesService';
import LanguageContext from '../../LanguageContext';

const CategoryForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isNonMobile = useMediaQuery('(min-width:600px)');
  const { language } = useContext(LanguageContext);
  const defaultLanguage = language === 0 ? croatian : english;
  const categoryToEdit = location.state || {};

  const [errors, setErrors] = useState([]);

  const checkoutSchema = yup.object().shape({
    name: yup.string().required('required'),
  });

  const initialValues = {
    name: !!categoryToEdit.name ? categoryToEdit.name : '',
  };

  const handleFormSubmit = (values) => {
    console.log(values);
  };

  useEffect(() => {
    if (errors.length > 0) {
      let sviErrori = errors.join(', ');

      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: defaultLanguage['categories-form-error-title'],
        html: sviErrori,
        showConfirmButton: false,
        timer: 4000,
      });
    }
  }, [errors]);

  const handleSubmitForm = async (values) => {
    values.preventDefault();

    const valuesApproved = checkFormIntegrity(values.target[0].value);

    if (valuesApproved.formErrors.length === 0) {
      const response = await AddNewCategory(
        JSON.stringify(valuesApproved.allInputs)
      );

      if (response.status === 200) {
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: defaultLanguage['categories-success-add'],
          html: response.input,
          showConfirmButton: false,
          timer: 1500,
        });

        navigate('/categories');
      } else if (response.status === 409) {
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: defaultLanguage['categories-name-exist'],
          html: response.error,
          showConfirmButton: false,
          timer: 4000,
        });
      }
    } else {
      return setErrors(valuesApproved.formErrors);
    }
  };

  const handleEditForm = async (categoryValues) => {
    const valuesApproved = checkFormIntegrity(categoryValues.name);

    if (valuesApproved.formErrors.length === 0) {
      valuesApproved['allInputs'].id = categoryToEdit.id;

      const responseEdit = await EditCategory(valuesApproved['allInputs']);

      if (responseEdit.data.status === 200) {
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: defaultLanguage['categories-success-edit'],
          html: responseEdit.data.info,
          showConfirmButton: false,
          timer: 1500,
        });

        navigate('/categories');
      } else if (responseEdit.data.status === 400) {
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: defaultLanguage['categories-name-exist'],
          html: responseEdit.data.error,
          showConfirmButton: false,
          timer: 4000,
        });
      }
    } else {
      return setErrors(valuesApproved.formErrors);
    }
  };

  const checkFormIntegrity = (value) => {
    let formErrors = [];

    let allInputs = {};

    if (value === '') {
      formErrors.push(defaultLanguage['categories-form-error-content']);
    } else {
      allInputs.name = value;
    }

    return { formErrors, allInputs };
  };

  const headerForm =
    Object.keys(categoryToEdit).length === 0 ? (
      <Header
        title={defaultLanguage['categories-form-new-title']}
        subtitle={defaultLanguage['categories-form-new-subtitle']}
      />
    ) : (
      <Header
        title={defaultLanguage['categories-form-edit-title']}
        subtitle={defaultLanguage['categories-form-edit-subtitle']}
      />
    );

  return (
    <>
      <Box m='20px'>
        {headerForm}

        <Formik
          onSubmit={handleFormSubmit}
          initialValues={initialValues}
          validationSchema={checkoutSchema}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmitForm}>
              <Box
                display='grid'
                gap='30px'
                gridTemplateColumns='repeat(4, minmax(0, 1fr))'
                sx={{
                  '& > div': { gridColumn: isNonMobile ? undefined : 'span 4' },
                }}
              >
                <TextField
                  fullWidth
                  variant='filled'
                  type='text'
                  label={defaultLanguage['categories-table-title']}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.name}
                  name='name'
                  error={!!touched.name && !!errors.name}
                  helperText={touched.name && errors.name}
                  sx={{ gridColumn: 'span 2' }}
                />
              </Box>

              <Box
                display='flex'
                flexDirection='column'
                width={isNonMobile ? '20%' : '100%'}
                style={{ marginTop: '1rem' }}
              >
                {Object.keys(categoryToEdit).length === 0 ? (
                  <Button type='submit' color='secondary' variant='contained'>
                    {defaultLanguage['categories-form-button-create']}
                  </Button>
                ) : (
                  <Button
                    onClick={() => handleEditForm(values)}
                    color='secondary'
                    variant='contained'
                  >
                    {defaultLanguage['categories-form-button-update']}
                  </Button>
                )}
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    </>
  );
};

export default CategoryForm;
