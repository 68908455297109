import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import * as React from 'react';

const DateAndTimePicker = ({ getDateTime, defaultValue }) => {
  const handleOnAccept = (e) => {
    const dateAndTimeString = formatDateTime(e.$d);
    getDateTime(dateAndTimeString);
  };

  const defaultDateTime = dayjs(defaultValue);

  const formatDateTime = (date) => {
    const padZero = (value) => (value < 10 ? `0${value}` : value);
    const year = date.getFullYear();
    const month = padZero(date.getMonth() + 1);
    const day = padZero(date.getDate());
    const hours = padZero(date.getHours());
    const minutes = padZero(date.getMinutes());
    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateTimePicker ampm={false} defaultValue={defaultDateTime} onAccept={handleOnAccept} className='date-time-picker'/>
    </LocalizationProvider>
  );
};

export default DateAndTimePicker;
