import { Box, useTheme, Button } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { tokens } from '../../theme';
import LanguageContext from '../../LanguageContext';
import Header from '../../components/Header';
import { useState, useEffect, useContext } from 'react';
import { GetGalleries, DeleteGallery } from '../../services/GalleriesService';
import { useNavigate } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import Swal from 'sweetalert2';
import english from '../../locales/english.json';
import croatian from '../../locales/croatian.json';

const Galleries = () => {
  const navigate = useNavigate();
  const isNonMobile = useMediaQuery('(min-width:600px)');
  const { language } = useContext(LanguageContext);
  const defaultLanguage = language === 0 ? croatian : english;

  const [galleries, setGalleries] = useState([]);
  const [isEditDisabled, setIsEditDisabled] = useState(true);
  const [isDeleteDisabled, setIsDeleteDisabled] = useState(true);
  const [selectedRow, setSelectedRow] = useState([]);
  const [isDeleted, setIsDeleted] = useState(false);
  const [galleryForEdit, setGalleryForEdit] = useState({});

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const columns = [
    { field: 'id', headerName: 'ID' },
    {
      field: 'name',
      headerName: defaultLanguage['galleries-table-name'],
      flex: 1,
    },
    {
      field: 'posts_with_gallery',
      headerName: defaultLanguage['galleries-table-times-used'],
      flex: 1,
    },
    {
      field: 'date_created',
      headerName: defaultLanguage['galleries-table-date-created'],
      flex: 1,
    },
    {
      field: 'date_updated',
      headerName: defaultLanguage['galleries-table-date-updated'],
      flex: 1,
    },
  ];

  const findGalleriesQuery = () => {
    const findGalleries = async () => {
      const response = await GetGalleries();

      if (response.data.status === 404) {
        setGalleries([]);
      } else {
        const galleries = response.data.galleries.map((item) => {
          item['id'] = parseInt(item['id']);
          return item;
        });

        setGalleries(galleries);
      }
    };
    findGalleries();
  };

  useEffect(() => {
    findGalleriesQuery();
  }, []);

  useEffect(() => {
    if (isDeleted) {
      findGalleriesQuery();
      setIsDeleted(false);
    }
  }, [isDeleted]);

  const checkButtons = (data) => {
    setSelectedRow(data);

    let isEditBtnDisabled = false;
    let isDeleteBtnDisabled = false;

    if (data.length === 1) {
      isEditBtnDisabled = false;
    } else {
      isEditBtnDisabled = true;
    }

    if (data.length > 0) {
      isDeleteBtnDisabled = false;
    } else {
      isDeleteBtnDisabled = true;
    }

    if (!isEditBtnDisabled) {
      collectGalleryDataForEdit(data);
    }

    setIsEditDisabled(isEditBtnDisabled);
    setIsDeleteDisabled(isDeleteBtnDisabled);
  };

  const collectGalleryDataForEdit = (rowId) => {
    const rowIdTest = parseInt(rowId[0]);

    const selectedGallery = galleries.find(
      (gallery) => gallery.id === rowIdTest
    );

    setGalleryForEdit(selectedGallery);
  };

  const DeleteGalleryQuery = async () => {
    await Swal.fire({
      title: defaultLanguage['delete-galleries-title'],
      text: defaultLanguage['delete-galleries-text'],
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: defaultLanguage['confirm-button-text'],
      cancelButtonText: defaultLanguage['cancel-button-text'],
    }).then(async (result) => {
      if (result.isConfirmed) {
        const responseDelete = await DeleteGallery(selectedRow);

        if (responseDelete.status === 200) {
          setIsDeleted(true);
          Swal.fire(defaultLanguage['galleries-success-delete']);
        }
      }
    });
  };

  return (
    <Box m='20px'>
      <Header
        title={defaultLanguage['galleries-title']}
        subtitle={defaultLanguage['galleries-subtitle']}
      />

      <div
        style={
          !isNonMobile ? { display: 'flex', flexDirection: 'column' } : null
        }
      >
        <Button
          onClick={() => navigate('/gallery-form')}
          variant='contained'
          color='secondary'
        >
          {defaultLanguage['galleries-button-add']}
        </Button>
        <Button
          onClick={() => navigate('/gallery-form', { state: galleryForEdit })}
          variant='contained'
          color='warning'
          disabled={isEditDisabled}
          style={isNonMobile ? { margin: '0 .5rem' } : { margin: '.5rem 0' }}
        >
          {defaultLanguage['galleries-button-edit']}
        </Button>
        <Button
          onClick={() => DeleteGalleryQuery()}
          variant='contained'
          color='error'
          disabled={isDeleteDisabled}
        >
          {defaultLanguage['galleries-button-delete']}
        </Button>
      </div>

      <Box
        m='40px 0 0 0'
        height='75vh'
        sx={{
          '& .MuiDataGrid-root': {
            border: 'none',
          },
          '& .MuiDataGrid-cell': {
            borderBottom: 'none',
          },
          '& .name-column--cell': {
            color: colors.greenAccent[300],
          },
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: colors.blueAccent[700],
            borderBottom: 'none',
          },
          '& .MuiDataGrid-virtualScroller': {
            backgroundColor: colors.primary[400],
          },
          '& .MuiDataGrid-footerContainer': {
            borderTop: 'none',
            backgroundColor: colors.blueAccent[700],
          },
          '& .MuiCheckbox-root': {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <DataGrid
          onSelectionModelChange={checkButtons}
          checkboxSelection
          rows={galleries.status === 404 ? [] : galleries}
          columns={columns}
        />
      </Box>
    </Box>
  );
};

export default Galleries;
