import React from 'react';
import axios from 'axios';

const targetAPI = process.env.REACT_APP_API_URL;

export const GetCategories = async () => {
  return await axios.get(`${targetAPI}/categories.php`);
};

export const AddNewCategory = async (allInputs) => {
  return await axios
    .post(`${targetAPI}/categories.php`, allInputs, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

export const DeleteCategory = async (props) => {
  return axios
    .delete(`${targetAPI}/categories.php`, {
      data: {
        id: props,
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
    });
};

export const EditCategory = async (valuesApproved) => {
  return await axios
    .put(`${targetAPI}/categories.php`, valuesApproved)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
    });
};
