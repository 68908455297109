import React from 'react';
import axios from 'axios';

const targetAPI = process.env.REACT_APP_API_URL;

export const GetGalleries = async () => {
  return await axios.get(`${targetAPI}/galleries.php`);
};

export const GetGalleryImages = async (galleryId) => {
  const url = `${targetAPI}/galleries.php?galleryId=${galleryId}`;
  return await axios.get(url);
};

export const AddNewGallery = async (allInputs) => {
  return await axios
    .post(`${targetAPI}/galleries.php`, allInputs, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

export const DeleteGallery = async (props) => {
  return axios
    .delete(`${targetAPI}/galleries.php`, {
      data: {
        id: props,
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
    });
};

export const EditGallery = async (valuesApproved) => {
  return await axios
    .put(`${targetAPI}/galleries.php`, valuesApproved)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
    });
};

export const DeleteUnusedImagesFromServer = async (
  galleryId,
  imagesListNames
) => {
  const listOfImageNamesForDeletion = imagesListNames.map(
    (image) => image.name
  );

  return await axios
    .post(
      `${targetAPI}/config/deleteImagesFromGalleryFolder.php`,
      { galleryId, imagesList: listOfImageNamesForDeletion },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

export const ResizeImage = async (file) => {
  console.log(file);
};

export const UploadImage = async (file, galleryId) => {
  const formData = new FormData();
  formData.append('image', file);
  formData.append('galleryId', galleryId);
  let url = `${targetAPI}/config/uploadGallery.php`;

  return await axios
    .post(url, formData)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(error);
    });
};
