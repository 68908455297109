import React from 'react';
import axios from 'axios';

const targetAPI = process.env.REACT_APP_API_URL;

export const GetPosts = async () => {
  return await axios.get(`${targetAPI}/posts.php`);
};

export const AddNewPost = async (allInputs) => {
  return await axios
    .post(`${targetAPI}/posts.php`, allInputs, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

export const DeletePost = async (props) => {
  return axios
    .delete(`${targetAPI}/posts.php`, {
      data: {
        id: props,
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
    });
};

export const EditPost = async (valuesApproved) => {
  return await axios
    .put(`${targetAPI}/posts.php`, valuesApproved)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
    });
};

export const UploadImage = async (file, postId) => {
  const formData = new FormData();
  formData.append('image', file);
  formData.append('post_id', postId);
  let url = `${targetAPI}/config/uploadImage.php`;

  return await axios
    .post(url, formData)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(error);
    });
};

export const UploadDocuments = async (formData) => {
  const url = `${targetAPI}/config/uploadDocuments.php`;

  return await axios
    .post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data', // Make sure to set proper content type for FormData
      },
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(error);
    });
};

export const DeleteUnusedFilesFromServer = async (postId, filesToDelete) => {
  return await axios
    .post(
      `${targetAPI}/config/deleteFilesFromDocumentsFolder.php`,
      { postId: postId, filesList: filesToDelete },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};
