import { Box, Button, ImageListItem, MenuItem, TextField } from '@mui/material';
import { Formik } from 'formik';
import * as yup from 'yup';
import Header from '../../components/Header';
import { useState, useEffect, useContext } from 'react';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import english from '../../locales/english.json';
import croatian from '../../locales/croatian.json';
import {
  AddNewCustomer,
  EditCustomer,
  UploadImage,
} from '../../services/CustomerService';
import LanguageContext from '../../LanguageContext';

const CustomerForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { language } = useContext(LanguageContext);
  const defaultLanguage = language === 0 ? croatian : english;
  const customerToEdit = location.state || {};

  const [name, setName] = useState('');
  const [file, setFile] = useState({});
  const [imageName, setImageName] = useState(!!file.name ? file.name : '');
  const [status, setStatus] = useState(
    !!customerToEdit.status ? customerToEdit.status : 0
  );
  const [errors, setErrors] = useState([]);
  const [isPictureDefaultLogo, setIsPictureDefaultLogo] = useState(
    Object.keys(customerToEdit).length
      ? customerToEdit.picture.endsWith('default_tpbj_logo.png')
      : false
  );

  const checkoutSchema = yup.object().shape({
    name: yup.string().required('required'),
    about: yup.string().required('required'),
    lat: yup.string().required('required'),
    lng: yup.string().required('required'),
    status: yup.string().required('required'),
  });

  const initialValues = {
    name: !!customerToEdit.name ? customerToEdit.name : '',
    about: !!customerToEdit.about ? customerToEdit.about : '',
    url: !!customerToEdit.url ? customerToEdit.url : '',
    lat: !!customerToEdit.lat ? customerToEdit.lat : '45.89751476705431',
    lng: !!customerToEdit.lng ? customerToEdit.lng : '16.842583764714004',
    status: !!customerToEdit.status ? customerToEdit.status : '',
    picture: !!customerToEdit.picture ? customerToEdit.picture : '',
  };

  const onChangeImg = (event) => {
    setImageName(event.target.files[0]['name']);
    setName(URL.createObjectURL(event.target.files[0]));
    setFile(event.target.files[0]);
    setIsPictureDefaultLogo(false);
  };

  const removePhoto = () => {
    customerToEdit.picture = '';
    setImageName('');
    setName('');
    setFile({});
  };

  const disableDeleteImageButton = () => {
    let isButtonDisabled = true;

    if (Object.keys(customerToEdit).length) {
      if (customerToEdit.picture !== '') {
        isButtonDisabled = false;
      }
    }

    if (Object.keys(file).length || imageName !== '' || name !== '') {
      isButtonDisabled = false;
    }

    return isButtonDisabled;
  };

  useEffect(() => {
    if (errors.length > 0) {
      let sviErrori = errors.join(', ');

      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: defaultLanguage['login-form-input-validation'],
        html: sviErrori,
        showConfirmButton: false,
        timer: 4000,
      });
    }
  }, [errors, defaultLanguage]);

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };

  const handleFormSubmit = (values) => {
    console.log(values);
  };

  const handleSubmitForm = async (values) => {
    values.preventDefault();

    const valuesApproved = checkFormIntegrity(values.target);

    if (valuesApproved.formErrors.length === 0) {
      if (name !== '') {
        const responseUpload = await UploadImage(file);

        if (responseUpload.data.status === 200) {
          valuesApproved.allInputs.picture = file.name;
        }
      } else {
        valuesApproved.allInputs.picture = '';
      }

      valuesApproved.allInputs.status = status;
      valuesApproved.allInputs.url = values.target[2].value;
      valuesApproved.allInputs.lat = values.target[3].value;
      valuesApproved.allInputs.lng = values.target[4].value;

      const response = await AddNewCustomer(
        JSON.stringify(valuesApproved.allInputs)
      );

      if (response.status === 200) {
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: defaultLanguage['customer-success-add'],
          html: response.info,
          showConfirmButton: false,
          timer: 1500,
        });

        navigate('/customers');
      } else {
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: defaultLanguage['customer-email-exist'],
          html: response.info,
          showConfirmButton: false,
          timer: 4000,
        });
      }
    } else {
      return setErrors(valuesApproved.formErrors);
    }
  };

  const handleEditForm = async (customerValues) => {
    customerValues.status = status;
    const valuesApproved = checkFormIntegrity(customerValues, true);

    if (valuesApproved.formErrors.length === 0) {
      if (!!valuesApproved['allInputs'].picture || imageName) {
        const responseUpload = await UploadImage(file, customerToEdit['id']);

        if (responseUpload.status === 200) {
          valuesApproved['allInputs'].picture = imageName;
        }
      } else if (customerToEdit.picture) {
        console.log(customerToEdit.picture);
      } else {
        valuesApproved['allInputs'].picture = '';
      }

      valuesApproved['allInputs'].id = customerToEdit.id;
      valuesApproved['allInputs'].status = status;
      valuesApproved['allInputs'].url = !!customerValues.url
        ? customerValues.url
        : '';
      valuesApproved['allInputs'].lat = customerValues.lat;
      valuesApproved['allInputs'].lng = customerValues.lng;

      const responseEdit = await EditCustomer(valuesApproved['allInputs']);

      if (responseEdit.status === 200) {
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: defaultLanguage['customer-success-edit'],
          html: responseEdit.data.info,
          showConfirmButton: false,
          timer: 1500,
        });

        navigate('/customers');
      }
    } else {
      return setErrors(valuesApproved.formErrors);
    }
  };

  const checkFormIntegrity = (values, isEdit = false) => {
    let formErrors = [];

    let allInputs = {};

    if (isEdit) {
      Object.keys(values).forEach((item) => {
        const singleItem = values[item];

        switch (item) {
          case 'name':
            if (singleItem === '') {
              formErrors.push(defaultLanguage['general-form-fields-name']);
            } else {
              allInputs.name = singleItem;
            }

            break;

          case 'about':
            if (singleItem === '') {
              formErrors.push(defaultLanguage['general-form-fields-about']);
            } else {
              allInputs.about = singleItem;
            }

            break;

          case 'status':
            if (singleItem === '') {
              formErrors.push(defaultLanguage['general-form-fields-status']);
            } else {
              allInputs.status = singleItem;
            }

            break;

          default:
            break;
        }
      });
    } else {
      Object.keys(values).forEach((item) => {
        const singleItem = values[item];

        if (
          singleItem.tagName === 'INPUT' ||
          singleItem.tagName === 'TEXTAREA'
        ) {
          switch (singleItem.name) {
            case 'name':
              if (singleItem.defaultValue === '') {
                formErrors.push(defaultLanguage['general-form-fields-name']);
              } else {
                allInputs.name = singleItem.defaultValue;
              }

              break;

            case 'about':
              if (singleItem.defaultValue === '') {
                formErrors.push(defaultLanguage['general-form-fields-about']);
              } else {
                allInputs.about = singleItem.defaultValue;
              }

              break;

            case 'status':
              if (singleItem.defaultValue === '') {
                formErrors.push(defaultLanguage['general-form-fields-status']);
              } else {
                allInputs.status = singleItem.defaultValue;
              }

              break;

            default:
              break;
          }
        }
      });
    }

    return { formErrors, allInputs };
  };

  const headerForm =
    Object.keys(customerToEdit).length === 0 ? (
      <Header
        title={defaultLanguage['customer-form-new-title']}
        subtitle={defaultLanguage['customer-form-new-subtitle']}
      />
    ) : (
      <Header
        title={defaultLanguage['customer-form-edit-title']}
        subtitle={defaultLanguage['customer-form-edit-subtitle']}
      />
    );

  return (
    <>
      <div style={{ textAlign: 'center' }}>{headerForm}</div>
      <Box
        display='flex'
        justifyContent='center'
        style={{ paddingBottom: '5rem' }}
      >
        <Box width='75%'>
          <Formik
            onSubmit={handleFormSubmit}
            initialValues={initialValues}
            validationSchema={checkoutSchema}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
            }) => (
              <form onSubmit={handleSubmitForm}>
                <Box display='flex' flexDirection='column' gap='20px'>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='text'
                    label={defaultLanguage['customers-table-name']}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.name}
                    name='name'
                    error={!!touched.name && !!errors.name}
                    helperText={defaultLanguage['customers-table-name-helper']}
                  />
                  <TextField
                    fullWidth
                    variant='filled'
                    type='text'
                    label={defaultLanguage['customers-table-about']}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.about}
                    name='about'
                    error={!!touched.about && !!errors.about}
                    helperText={defaultLanguage['customers-table-about-helper']}
                  />
                  <TextField
                    fullWidth
                    variant='filled'
                    type='text'
                    label={defaultLanguage['customers-table-url']}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.url}
                    name='url'
                    error={!!touched.url && !!errors.url}
                    helperText={defaultLanguage['customers-table-url-helper']}
                  />
                  <TextField
                    fullWidth
                    variant='filled'
                    type='text'
                    label={defaultLanguage['customers-table-lat']}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.lat}
                    name='lat'
                    error={!!touched.lat && !!errors.lat}
                    helperText={defaultLanguage['customers-table-lat-helper']}
                  />
                  <TextField
                    fullWidth
                    variant='filled'
                    type='text'
                    label={defaultLanguage['customers-table-lng']}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.lng}
                    name='lng'
                    error={!!touched.lng && !!errors.lng}
                    helperText={defaultLanguage['customers-table-lng-helper']}
                  />

                  <TextField
                    select
                    label='Status'
                    name='status'
                    helperText={
                      defaultLanguage['customers-table-status-helper']
                    }
                    variant='filled'
                    value={status}
                    onChange={handleStatusChange}
                  >
                    <MenuItem key={1} value={0}>
                      {defaultLanguage['post-form-status-draft']}
                    </MenuItem>
                    <MenuItem key={2} value={1}>
                      {defaultLanguage['post-form-status-published']}
                    </MenuItem>
                  </TextField>

                  <h2>Vodeća fotografija</h2>
                  {name || customerToEdit.picture ? (
                    <Box display='flex' justifyContent='center'>
                      <Box
                        style={{
                          padding: '.5rem',
                          border: isPictureDefaultLogo ? null : '1px solid',
                        }}
                      >
                        <ImageListItem>
                          <img
                            src={
                              name
                                ? name
                                : customerToEdit.picture
                                ? `${customerToEdit.picture}`
                                : ''
                            }
                            alt={`${customerToEdit.picture || imageName}`}
                            loading='lazy'
                            style={{
                              width: isPictureDefaultLogo ? '200px' : null,
                            }}
                          />
                        </ImageListItem>
                      </Box>
                    </Box>
                  ) : null}

                  <Box
                    display='flex'
                    justifyContent={'space-between'}
                    gap='10px'
                    alignItems='center'
                  >
                    <Button
                      variant='contained'
                      component='label'
                      color='secondary'
                    >
                      {defaultLanguage['post-form-button-select-image']}
                      <input
                        accept='image/*'
                        type='file'
                        onChange={onChangeImg}
                        hidden
                      />
                    </Button>
                    <Button
                      variant='contained'
                      component='label'
                      color='error'
                      onClick={removePhoto}
                      style={{ wordBreak: 'break-all' }}
                      disabled={disableDeleteImageButton()}
                    >
                      {defaultLanguage['post-form-button-delete-image']}
                    </Button>
                  </Box>

                  {Object.keys(customerToEdit).length === 0 ? (
                    <Button type='submit' color='secondary' variant='contained'>
                      {defaultLanguage['customers-form-button-create']}
                    </Button>
                  ) : (
                    <Button
                      onClick={() => handleEditForm(values)}
                      color='secondary'
                      variant='contained'
                    >
                      {defaultLanguage['customers-form-button-update']}
                    </Button>
                  )}
                </Box>
              </form>
            )}
          </Formik>
        </Box>
      </Box>
    </>
  );
};

export default CustomerForm;
