import * as React from 'react';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme } from '@mui/material/styles';
import LanguageContext from '../../LanguageContext';
import english from '../../locales/english.json';
import croatian from '../../locales/croatian.json';
import Swal from 'sweetalert2';
import axios from 'axios';

const targetAPI = process.env.REACT_APP_API_URL;

function Copyright(props) {
  return (
    <Typography
      variant='body2'
      color='text.secondary'
      align='center'
      {...props}
    >
      {'Copyright © '}
      <Link color='inherit' href='https://tpbj.hr/' target={'_blank'}>
        Tehnološki Park Bjelovar
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const theme = createTheme();

export default function Login() {
  const navigate = useNavigate();
  const { language } = useContext(LanguageContext);
  const defaultLanguage = language === 0 ? croatian : english;

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const loginEmail = data.get('email');
    const loginPassword = data.get('password');

    if (!!loginEmail && !!loginPassword) {
      const loginInformation = {
        email: data.get('email'),
        password: data.get('password'),
      };

      const loginInfo = await axios
        .post(`${targetAPI}/config/login.php`, loginInformation, {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then(async (response) => {
          if (response.data.status === 200) {
            localStorage.setItem('token', response.data.token);
            localStorage.setItem(
              'administratorInfo',
              JSON.stringify(response.data.data)
            );
            await Swal.fire({
              position: 'top-end',
              icon: 'success',
              title: 'Welcome to AMCms',
              html: defaultLanguage['login-form-success'],
              showConfirmButton: false,
              timer: 1500,
            });
            return response.data;
          } else if (response.data.status === 401) {
            Swal.fire({
              position: 'top-end',
              icon: 'error',
              title: defaultLanguage['login-form-passwords-match'],
              showConfirmButton: false,
              timer: 1500,
            });
            return response.data;
          } else {
            Swal.fire({
              position: 'top-end',
              icon: 'error',
              title: response.data.info,
              showConfirmButton: false,
              timer: 1500,
            });
            return response.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });

      if (loginInfo.status === 200) {
        navigate('/');
        window.location.reload();
      }
    } else {
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: defaultLanguage['login-form-input-validation'],
        html: defaultLanguage['login-form-input-validation-subtitle'],
        showConfirmButton: false,
        timer: 2500,
      });
    }
  };

  return (
    <Container component='main' maxWidth='xs'>
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component='h1' variant='h5'>
          {defaultLanguage['login-form-title']}
        </Typography>
        <Box component='form' onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin='normal'
            required
            fullWidth
            id='email'
            label={defaultLanguage['administrators-table-email']}
            name='email'
            autoComplete='email'
            autoFocus
          />
          <TextField
            margin='normal'
            required
            fullWidth
            name='password'
            label={defaultLanguage['administrators-table-password']}
            type='password'
            id='password'
            autoComplete='current-password'
          />
          {/* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            /> */}
          <Button
            type='submit'
            fullWidth
            variant='contained'
            color='secondary'
            sx={{ mt: 3, mb: 2 }}
          >
            {defaultLanguage['login-form-title']}
          </Button>
          {/* <Link href="#" variant="body2" style={{ display: "flex", justifyContent: "center"}}>
              {defaultLanguage['login-form-forgot-password']}
            </Link> */}
        </Box>
      </Box>
      <Copyright sx={{ mt: 8, mb: 4 }} />
    </Container>
  );
}
