import { Box, Button, ImageList, TextField } from '@mui/material';
import { Formik } from 'formik';
import * as yup from 'yup';
import useMediaQuery from '@mui/material/useMediaQuery';
import Header from '../../components/Header';
import React, { useState, useEffect, useContext, useRef } from 'react';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import english from '../../locales/english.json';
import croatian from '../../locales/croatian.json';
import {
  GetGalleryImages,
  AddNewGallery,
  EditGallery,
  DeleteUnusedImagesFromServer,
  UploadImage,
} from '../../services/GalleriesService';
import LanguageContext from '../../LanguageContext';
import {
  DndContext,
  closestCenter,
  useSensors,
  useSensor,
  PointerSensor,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  rectSortingStrategy,
} from '@dnd-kit/sortable';
import { SortableGallery } from '../../components/SortableGallery';
import { LoadingButton } from '@mui/lab';

const targetAPI = process.env.REACT_APP_API_URL;

const GalleryForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isNonMobile = useMediaQuery('(min-width:600px)');
  const { language } = useContext(LanguageContext);
  const fileInputRef = useRef(null);
  const defaultLanguage = language === 0 ? croatian : english;
  let galleryToEdit = location.state || {};

  const [errors, setErrors] = useState([]);
  const [urlImages, setUrlImages] = useState([]);
  const [fileImages, setFileImages] = useState([]);
  const [dbImages, setDbImages] = useState([]);
  const [numberOfPicturesInGallery, setNumberOfPicturesInGallery] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  let buildUrlToImagesFolder = '';
  if (!!galleryToEdit.id) {
    buildUrlToImagesFolder = `${targetAPI}/images/galleries/${galleryToEdit.id}/`;
  }

  let shouldUpdateButtonBeDisabled = false;
  if (
    urlImages.length === 0 &&
    fileImages.length === 0 &&
    dbImages.length === 0 &&
    numberOfPicturesInGallery === 0
  ) {
    shouldUpdateButtonBeDisabled = true;
  }

  useEffect(() => {
    if (errors.length > 0) {
      let sviErrori = errors.join(', ');

      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: defaultLanguage['galleries-form-error-title'],
        html: sviErrori,
        showConfirmButton: false,
        timer: 4000,
      });
    }
  }, [errors]);

  useEffect(() => {
    if (fileImages.length > 0) {
      let arrayWithImageUrls = [];
      fileImages.forEach((image) => {
        arrayWithImageUrls.push(image.url);
      });

      if (arrayWithImageUrls.length > 0) {
        if (urlImages.length > 0) {
          const uniqueUrls = arrayWithImageUrls.filter(
            (url) => !urlImages.includes(url)
          );
          setUrlImages((prevImages) => [...prevImages, ...uniqueUrls]);
        } else {
          setUrlImages(arrayWithImageUrls);
        }
      }
    }
  }, [fileImages]);

  useEffect(() => {
    if (dbImages.length > 0) {
      let arrayWithImageUrls = [];

      dbImages.forEach((image) => {
        image['url'] = `${buildUrlToImagesFolder}${image.name}`;
        arrayWithImageUrls.push(image['url']);
      });

      if (arrayWithImageUrls.length > 0) {
        setUrlImages(arrayWithImageUrls);
      }
    } else {
      setUrlImages([]);
    }
  }, [dbImages]);

  useEffect(() => {
    if (!!galleryToEdit.id) {
      findGalleryImages();
    }
  }, []);

  const findGalleryImages = async () => {
    const response = await GetGalleryImages(galleryToEdit.id);

    if (response.data.status === 404) {
      setDbImages([]);
    } else {
      const images = !!response.data.galleries[0].images
        ? JSON.parse(response.data.galleries[0].images)
        : [];
      setNumberOfPicturesInGallery(images.length);
      setDbImages(images);
    }
  };

  const checkoutSchema = yup.object().shape({
    name: yup.string().required('required'),
  });

  const initialValues = {
    name: !!galleryToEdit.name ? galleryToEdit.name : '',
  };

  const onDragOver = (event) => {
    event.preventDefault();
    setIsDragging(true);
    event.dataTransfer.dropEffect = 'copy';
  };

  const onDragLeave = (event) => {
    event.preventDefault();
    setIsDragging(false);
  };

  const handleDragEnd = async (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      setUrlImages((items) => {
        const activeIndex = items.indexOf(active.id);
        const overIndex = items.indexOf(over.id);

        return arrayMove(items, activeIndex, overIndex);
      });
    }
  };

  const selectFiles = () => {
    fileInputRef.current.click();
  };

  const onFileSelect = (event) => {
    const files = event.target.files;

    if (files.length === 0) {
      return;
    }

    for (let i = 0; i < files.length; i++) {
      if (files[i].type.split('/')[0] !== 'image') {
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: defaultLanguage['galleries-wrong-picture-format'],
          html: files[i].name,
          showConfirmButton: false,
          timer: 4000,
        });
        continue;
      }

      if (!fileImages.some((e) => e.name === files[i].name)) {
        const file = files[i];
        const reader = new FileReader();

        reader.onload = async () => {
          const arrayBuffer = await fetch(reader.result).then((response) =>
            response.arrayBuffer()
          );
          const convertedFile = new File([arrayBuffer], file.name, {
            type: file.type,
          });
          convertedFile['url'] = URL.createObjectURL(files[i]);

          setFileImages((prevImages) => [...prevImages, convertedFile]);
        };

        reader.readAsDataURL(file);
      } else {
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: defaultLanguage['galleries-picture-exist'],
          html: `${files[i].name} exists`,
          showConfirmButton: false,
          timer: 4000,
        });
      }
    }
  };

  const onDrop = (event) => {
    event.preventDefault();
    setIsDragging(false);

    const files = event.dataTransfer.files;

    if (files.length === 0) {
      return;
    }

    for (let i = 0; i < files.length; i++) {
      if (files[i].type.split('/')[0] !== 'image') {
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: defaultLanguage['galleries-wrong-picture-format'],
          html: files[i].name,
          showConfirmButton: false,
          timer: 4000,
        });
        continue;
      }

      if (!dbImages.some((e) => e.name === files[i].name)) {
        if (!fileImages.some((e) => e.name === files[i].name)) {
          const file = files[i];
          const reader = new FileReader();

          reader.onload = async () => {
            const arrayBuffer = await fetch(reader.result).then((response) =>
              response.arrayBuffer()
            );
            const convertedFile = new File([arrayBuffer], file.name, {
              type: file.type,
            });
            convertedFile['url'] = URL.createObjectURL(files[i]);

            setFileImages((prevImages) => [...prevImages, convertedFile]);
          };

          reader.readAsDataURL(file);
        } else {
          Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: defaultLanguage['galleries-picture-exist'],
            html: files[i].name,
            showConfirmButton: false,
            timer: 4000,
          });
        }
      } else {
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: defaultLanguage['galleries-picture-exist'],
          html: files[i].name,
          showConfirmButton: false,
          timer: 4000,
        });
      }
    }
  };

  const uploadImages = async () => {
    if (fileImages.length > 0) {
      setIsLoading(true);
      let isThereGalleryUploadError = false;
      let galleryPicturesWithError = [];
      let arrayWithImageObjects = [];

      for (let index = 0; index <= fileImages.length - 1; index++) {
        const file = fileImages[index];
        const responseUpload = await UploadImage(file, galleryToEdit.id);

        if (responseUpload.data.status === 200) {
          const newImage = {
            sort: index,
            name: file.name,
            url: `${file.url}`,
          };
          arrayWithImageObjects.push(newImage);
        } else if (responseUpload.data.status !== 200) {
          isThereGalleryUploadError = true;
          galleryPicturesWithError.push(file.name);
        }
      }

      if (isThereGalleryUploadError) {
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: defaultLanguage['galleries-successfully-uploaded-error'],
          html: galleryPicturesWithError.map((picture) => picture),
          showConfirmButton: false,
          timer: 4000,
        });
      } else {
        arrayWithImageObjects = sortOldAndNewImages();

        const galleryValues = {
          name: galleryToEdit.name,
          images: JSON.stringify(arrayWithImageObjects),
        };

        const responseEdit = await handleEditForm(galleryValues);

        if (responseEdit.data.status === 200) {
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: defaultLanguage['galleries-successfully-uploaded'],
            html: arrayWithImageObjects.map((picture) => picture.name),
            showConfirmButton: false,
            timer: 4000,
          });

          setIsLoading(false);
        }
      }
    } else {
      const sortingWithoutUpload = sortOldAndNewImages();

      const galleryValues = {
        name: galleryToEdit.name,
        images: JSON.stringify(sortingWithoutUpload),
      };

      const responseEdit = await handleEditForm(galleryValues);

      if (responseEdit.data.status === 200) {
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: defaultLanguage['galleries-successfully-updated'],
          html: sortingWithoutUpload.map((picture) => picture.name),
          showConfirmButton: false,
          timer: 4000,
        });
      }
    }

    setUrlImages([]);
    setFileImages([]);
    setDbImages([]);
    findGalleryImages();
    DeleteUnusedImagesFromServer(galleryToEdit.id, sortOldAndNewImages());
  };

  const deleteImage = (image) => {
    if (image.startsWith('bl')) {
      if (fileImages.find((singleImage) => singleImage.url === image)) {
        setUrlImages(urlImages.filter((singleImage) => singleImage !== image));
        setFileImages(
          fileImages.filter((singleImage) => singleImage.url !== image)
        );
      }
    } else if (image.startsWith('ht')) {
      if (dbImages.find((singleImage) => singleImage.url === image)) {
        setUrlImages(urlImages.filter((singleImage) => singleImage !== image));
      }
    }
  };

  const sortOldAndNewImages = () => {
    const finalSortedGallery = [];

    urlImages.forEach((imageObject, index) => {
      if (imageObject.startsWith('ht')) {
        const selectedImage = dbImages.find(
          (imageFromDB) => imageFromDB.url === imageObject
        );
        finalSortedGallery.push({
          sort: index,
          name: selectedImage.name,
        });
      } else if (imageObject.startsWith('bl')) {
        const selectedImage = fileImages.find(
          (fileImage) => fileImage.url === imageObject
        );
        finalSortedGallery.push({
          sort: index,
          name: selectedImage.name,
        });
      }
    });

    return finalSortedGallery;
  };

  const handleFormSubmit = (values) => {
    console.log(values);
  };

  const handleSubmitForm = async (values) => {
    values.preventDefault();

    const valuesApproved = checkFormIntegrity(values.target);

    if (valuesApproved.formErrors.length === 0) {
      const response = await AddNewGallery(
        JSON.stringify(valuesApproved.allInputs)
      );

      if (response.status === 200) {
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: defaultLanguage['galleries-success-add'],
          html: response.info,
          showConfirmButton: false,
          timer: 1500,
        });

        navigate('/galleries');
      } else if (response.status === 409) {
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: defaultLanguage['galleries-name-exist'],
          html: response.message,
          showConfirmButton: false,
          timer: 4000,
        });
      }
    } else {
      return setErrors(valuesApproved.formErrors);
    }
  };

  const handleEditForm = async (galleryValues) => {
    const valuesApproved = checkFormIntegrity(galleryValues, true);

    if (valuesApproved.formErrors.length === 0) {
      valuesApproved['allInputs'].id = galleryToEdit.id;

      const responseEdit = await EditGallery(valuesApproved['allInputs']);

      if (responseEdit.status === 200) {
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: defaultLanguage['galleries-success-edit'],
          html: responseEdit.data.info,
          showConfirmButton: false,
          timer: 1500,
        });

        if (!!valuesApproved['allInputs'].images) {
          return responseEdit;
        } else {
          navigate('/galleries');
        }
      }
    } else {
      return setErrors(valuesApproved.formErrors);
    }
  };

  const checkFormIntegrity = (value, isEdit = false) => {
    let formErrors = [];
    let allInputs = {};

    let name = '';

    if (!isEdit) {
      name = value[0].name === 'name' ? value[0].value : '';
    } else {
      name = !!value.name ? value.name : '';
    }

    if (name === '') {
      formErrors.push(defaultLanguage['galleries-form-error-content']);
    } else {
      allInputs.name = name;

      if (!!value.images) {
        allInputs['images'] = value.images;
      }
    }

    return { formErrors, allInputs };
  };

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    })
  );

  const headerForm =
    Object.keys(galleryToEdit).length === 0 ? (
      <Header
        title={defaultLanguage['galleries-form-new-title']}
        subtitle={defaultLanguage['galleries-form-new-subtitle']}
      />
    ) : (
      <Header
        title={defaultLanguage['galleries-form-edit-title']}
        subtitle={defaultLanguage['galleries-form-edit-subtitle']}
      />
    );

  return (
    <>
      <Box m='20px'>
        {headerForm}

        <Formik
          onSubmit={handleFormSubmit}
          initialValues={initialValues}
          validationSchema={checkoutSchema}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmitForm}>
              <Box
                display='grid'
                gap='30px'
                gridTemplateColumns='repeat(1, minmax(0, 1fr))'
                sx={{
                  '& > div': { gridColumn: isNonMobile ? undefined : 'span 4' },
                }}
              >
                <TextField
                  fullWidth
                  variant='filled'
                  type='text'
                  label={defaultLanguage['galleries-table-name']}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.name}
                  name='name'
                  helperText={touched.name && errors.name}
                  sx={{ gridColumn: 'span 2' }}
                  disabled={isLoading}
                />
              </Box>

              {Object.keys(galleryToEdit).length > 0 ? (
                <div className='card'>
                  <div className='top'>
                    <h1>{defaultLanguage['galleries-drag-drop-title']}</h1>
                    <p>
                      {defaultLanguage['galleries-number-of-pictures']}{' '}
                      {numberOfPicturesInGallery}
                    </p>
                    {fileImages.length ? (
                      <p>
                        {
                          defaultLanguage[
                            'galleries-number-of-pictures-with-new-ones'
                          ]
                        }{' '}
                        {numberOfPicturesInGallery + fileImages.length}
                      </p>
                    ) : null}
                  </div>
                  <div
                    className='drag-area'
                    onDragOver={onDragOver}
                    onDragLeave={onDragLeave}
                    onDrop={onDrop}
                  >
                    {isDragging ? (
                      <span className='select'>
                        {defaultLanguage['galleries-drag-drop-area-1']}
                      </span>
                    ) : (
                      <>
                        {defaultLanguage['galleries-drag-drop-area-2']} {'  '}
                        <span
                          className='select-browse'
                          role='button'
                          onClick={selectFiles}
                        >
                          {defaultLanguage['galleries-drag-drop-area-3']}
                        </span>
                      </>
                    )}
                    <input
                      type='file'
                      name='file'
                      className='file'
                      multiple
                      ref={fileInputRef}
                      onChange={onFileSelect}
                    />
                  </div>
                  <ImageList cols={isNonMobile ? 6 : 1}>
                    <DndContext
                      collisionDetection={closestCenter}
                      onDragEnd={handleDragEnd}
                      sensors={sensors}
                    >
                      <SortableContext
                        items={urlImages}
                        strategy={rectSortingStrategy}
                        disabled={isLoading}
                      >
                        {urlImages.map((image, index) => (
                          <SortableGallery
                            isLoading={isLoading}
                            key={image}
                            image={image}
                            deleteImage={() => deleteImage(image)}
                          />
                        ))}
                      </SortableContext>
                    </DndContext>
                  </ImageList>

                  {isLoading ? (
                    <LoadingButton
                      className='loading-button'
                      loading
                      variant='outlined'
                    >
                      <span>Submit</span>
                    </LoadingButton>
                  ) : fileImages.length > 0 && !isLoading ? (
                    <Button
                      className='gallery-upload-button'
                      fullWidth
                      onClick={uploadImages}
                      color='secondary'
                      variant='contained'
                      disabled={false}
                    >
                      {defaultLanguage['galleries-drag-drop-button-upload']}
                    </Button>
                  ) : (
                    <Button
                      className='gallery-upload-button'
                      fullWidth
                      onClick={uploadImages}
                      color='info'
                      variant='contained'
                      disabled={shouldUpdateButtonBeDisabled}
                    >
                      {defaultLanguage['galleries-drag-drop-button-sort']}
                    </Button>
                  )}
                </div>
              ) : (
                <h1>{defaultLanguage['galleries-drag-drop-message']}</h1>
              )}

              <Box
                display='flex'
                flexDirection='column'
                width={isNonMobile ? '20%' : '100%'}
                style={{ marginTop: '1rem' }}
              >
                {Object.keys(galleryToEdit).length === 0 ? (
                  <Button type='submit' color='secondary' variant='contained'>
                    {defaultLanguage['galleries-form-button-create']}
                  </Button>
                ) : (
                  <Button
                    onClick={() => handleEditForm(values)}
                    color='secondary'
                    variant='contained'
                    disabled={values.name === galleryToEdit.name || isLoading}
                  >
                    {defaultLanguage['galleries-form-button-update']}
                  </Button>
                )}
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    </>
  );
};

export default GalleryForm;
