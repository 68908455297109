import { Box, useTheme, Avatar, Button } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { tokens } from '../../theme';
import LanguageContext from '../../LanguageContext';
import AdministratorContext from '../../AdministratorContext';
import Header from '../../components/Header';
import { useState, useEffect, useContext } from 'react';
import { GetCustomers, DeleteCustomer } from '../../services/CustomerService';
import { useNavigate } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import Swal from 'sweetalert2';
import english from '../../locales/english.json';
import croatian from '../../locales/croatian.json';

const targetAPI = process.env.REACT_APP_API_URL;

const Customers = () => {
  const navigate = useNavigate();
  const isNonMobile = useMediaQuery('(min-width:600px)');
  const { language } = useContext(LanguageContext);
  const defaultLanguage = language === 0 ? croatian : english;
  const { administratorInfo } = useContext(AdministratorContext);

  const [customers, setCustomers] = useState([]);
  const [isEditDisabled, setIsEditDisabled] = useState(true);
  const [isDeleteDisabled, setIsDeleteDisabled] = useState(true);
  const [selectedRow, setSelectedRow] = useState([]);
  const [isDeleted, setIsDeleted] = useState(false);
  const [customerForEdit, setCustomerForEdit] = useState({});

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const columns = [
    { field: 'id', headerName: 'ID' },
    {
      field: 'name',
      headerName: defaultLanguage['customers-table-name'],
      flex: 1,
    },
    {
      field: 'about',
      headerName: defaultLanguage['customers-table-about'],
      flex: 1,
    },
    {
      field: 'lat',
      headerName: defaultLanguage['customers-table-lat'],
      flex: 1,
    },
    {
      field: 'lng',
      headerName: defaultLanguage['customers-table-lng'],
      flex: 1,
    },
    {
      field: 'url',
      headerName: defaultLanguage['customers-table-url'],
      flex: 1,
    },
    {
      field: 'status',
      headerName: defaultLanguage['customers-table-status'],
      flex: 1,
    },
    {
      field: 'picture',
      headerName: defaultLanguage['customers-table-picture'],
      headerAlign: 'center',
      renderCell: ({ row: { picture } }) => {
        return (
          <>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
              }}
            >
              <Avatar src={`${picture}`} />
            </div>
          </>
        );
      },
    },
    {
      field: 'date_created',
      headerName: defaultLanguage['customers-table-date-created'],
      flex: 1,
    },
    {
      field: 'date_updated',
      headerName: defaultLanguage['customers-table-date-updated'],
      flex: 1,
    },
  ];

  const findCustomersQuery = () => {
    const findCustomers = async () => {
      const response = await GetCustomers();

      if (response.data.status === 404) {
        setCustomers([]);
      } else {
        const customers = response.data.customers.map((customer) => {
          customer['id'] = parseInt(customer['id']);
          customer['status'] = parseInt(customer['status']);
          customer['picture'] = customer['picture'].startsWith('http')
            ? customer['picture']
            : `${targetAPI}/${customer.picture}`;

          return customer;
        });

        if (administratorInfo.role === 'user') {
          const test = customers.find(
            (customer) => customer.name === administratorInfo.customer
          );
          setCustomers([test]);
        } else {
          setCustomers(customers);
        }
      }
    };
    findCustomers();
  };

  useEffect(() => {
    findCustomersQuery();
  }, []);

  useEffect(() => {
    if (isDeleted) {
      findCustomersQuery();
      setIsDeleted(false);
    }
  }, [isDeleted]);

  const checkButtons = (data) => {
    setSelectedRow(data);

    let isEditBtnDisabled = false;
    let isDeleteBtnDisabled = false;

    if (data.length === 1) {
      isEditBtnDisabled = false;
    } else {
      isEditBtnDisabled = true;
    }

    if (data.length > 0) {
      isDeleteBtnDisabled = false;
    } else {
      isDeleteBtnDisabled = true;
    }

    if (!isEditBtnDisabled) {
      collectCustomerDataForEdit(data);
    }

    setIsEditDisabled(isEditBtnDisabled);
    setIsDeleteDisabled(isDeleteBtnDisabled);
  };

  const collectCustomerDataForEdit = (rowId) => {
    const rowIdTest = parseInt(rowId[0]);

    const selectedCustomer = customers.find((post) => post.id === rowIdTest);

    setCustomerForEdit(selectedCustomer);
  };

  const DeleteCustomerQuery = async () => {
    await Swal.fire({
      title: defaultLanguage['delete-customer-title'],
      text: defaultLanguage['delete-customer-text'],
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: defaultLanguage['confirm-button-text'],
      cancelButtonText: defaultLanguage['cancel-button-text'],
    }).then(async (result) => {
      if (result.isConfirmed) {
        const responseDelete = await DeleteCustomer(selectedRow);

        if (responseDelete.status === 200) {
          setIsDeleted(true);
          Swal.fire(defaultLanguage['customer-success-delete']);
        }
      }
    });
  };

  return (
    <Box m='20px'>
      <Header
        title={defaultLanguage['customers-title']}
        subtitle={defaultLanguage['customers-subtitle']}
      />

      <div
        style={
          !isNonMobile ? { display: 'flex', flexDirection: 'column' } : null
        }
      >
        <Button
          onClick={() => navigate('/customer-form')}
          variant='contained'
          color='secondary'
        >
          {defaultLanguage['customers-button-add']}
        </Button>
        <Button
          onClick={() => navigate('/customer-form', { state: customerForEdit })}
          variant='contained'
          color='warning'
          disabled={isEditDisabled}
          style={isNonMobile ? { margin: '0 .5rem' } : { margin: '.5rem 0' }}
        >
          {defaultLanguage['customers-button-edit']}
        </Button>
        <Button
          onClick={() => DeleteCustomerQuery()}
          variant='contained'
          color='error'
          disabled={isDeleteDisabled}
        >
          {defaultLanguage['customers-button-delete']}
        </Button>
      </div>

      <Box
        m='40px 0 0 0'
        height='75vh'
        sx={{
          '& .MuiDataGrid-root': {
            border: 'none',
          },
          '& .MuiDataGrid-cell': {
            borderBottom: 'none',
          },
          '& .name-column--cell': {
            color: colors.greenAccent[300],
          },
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: colors.blueAccent[700],
            borderBottom: 'none',
          },
          '& .MuiDataGrid-virtualScroller': {
            backgroundColor: colors.primary[400],
          },
          '& .MuiDataGrid-footerContainer': {
            borderTop: 'none',
            backgroundColor: colors.blueAccent[700],
          },
          '& .MuiCheckbox-root': {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <DataGrid
          onSelectionModelChange={checkButtons}
          checkboxSelection
          rows={customers.status === 404 ? [] : customers}
          columns={columns}
        />
      </Box>
    </Box>
  );
};

export default Customers;
