import { useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Topbar from './scenes/global/Topbar';
import Sidebar from './scenes/global/Sidebar';
import Administrators from './scenes/administrators';
import Login from './scenes/login';
import Invoices from './scenes/invoices';
import Contacts from './scenes/contacts';
import AdministratorForm from './scenes/administrator-form';
import PostForm from './scenes/post-form';
import CategoryForm from './scenes/categories-form';
import FAQ from './scenes/faq';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { useMode } from './theme';
import Calendar from './scenes/calendar/calendar';
import Events from './scenes/events';
import Home from './scenes/home/HomePage';
import LanguageContext from './LanguageContext';
import AdministratorContext from './AdministratorContext';
import Posts from './scenes/posts';
import Categories from './scenes/categories';
import Galleries from './scenes/galleries/Galleries';
import GalleryForm from './scenes/gallery-form';
import Customers from './scenes/customers';
import CustomerForm from './scenes/customer-form';
import EventsForm from './scenes/events-form';

function App() {
  const languageFromLocalStorage = localStorage.getItem('language');
  const [theme] = useMode();
  const [language, setLanguage] = useState(
    !!languageFromLocalStorage ? +languageFromLocalStorage : 0
  );
  const [administratorInfo, setAdministratorInfo] = useState({});
  const [isSidebar, setIsSidebar] = useState(true);
  const token = localStorage.getItem('token');
  const isAuthorized = !!token;

  return (
    <LanguageContext.Provider value={{ language, setLanguage }}>
      <AdministratorContext.Provider
        value={{ administratorInfo, setAdministratorInfo }}
      >
        <ThemeProvider theme={theme}>
          <CssBaseline />
          {isAuthorized ? (
            <div className='app'>
              <Sidebar isSidebar={isSidebar} />
              <main className='content'>
                <Topbar setIsSidebar={setIsSidebar} />
                {Object.keys(administratorInfo).length ? (
                  <Routes>
                    <Route path='/' element={<Home />} />
                    {administratorInfo.role === 'administrator' ? (
                      <Route
                        path='/administrators'
                        element={<Administrators />}
                      />
                    ) : null}
                    <Route path='/posts' element={<Posts />} />
                    <Route path='/customers' element={<Customers />} />
                    <Route path='/categories' element={<Categories />} />
                    <Route path='/galleries' element={<Galleries />} />
                    <Route path='/events' element={<Events />} />
                    <Route path='/contacts' element={<Contacts />} />
                    <Route path='/invoices' element={<Invoices />} />
                    <Route
                      path='/administrator-form'
                      element={<AdministratorForm />}
                    />
                    <Route path='/post-form' element={<PostForm />} />
                    <Route path='/customer-form' element={<CustomerForm />} />
                    <Route path='/events-form' element={<EventsForm />} />
                    <Route path='/category-form' element={<CategoryForm />} />
                    <Route path='/gallery-form' element={<GalleryForm />} />
                    <Route path='/faq' element={<FAQ />} />
                    <Route path='/calendar' element={<Calendar />} />
                  </Routes>
                ) : null}
              </main>
            </div>
          ) : (
            <div className='app'>
              <main className='content'>
                <Topbar loginScreen={true} />
                <Routes>
                  <Route path='*' element={<Navigate to='/login' />} />
                  <Route path='/login' element={<Login />} />
                </Routes>
              </main>
            </div>
          )}
        </ThemeProvider>
      </AdministratorContext.Provider>
    </LanguageContext.Provider>
  );
}

export default App;
