import DateAndTimePicker from '../../components/DateAndTimePicker';

import { Box, Button, ImageListItem, MenuItem, TextField } from '@mui/material';
import { Formik } from 'formik';
import * as yup from 'yup';
import Header from '../../components/Header';
import { useState, useEffect, useContext } from 'react';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import english from '../../locales/english.json';
import croatian from '../../locales/croatian.json';
import {
  AddNewEvent,
  EditEvent,
  UploadImage,
} from '../../services/EventsService';
import LanguageContext from '../../LanguageContext';

const EventsForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { language } = useContext(LanguageContext);
  const defaultLanguage = language === 0 ? croatian : english;
  const eventToEdit = location.state || {};

  const [name, setName] = useState('');
  const [file, setFile] = useState({});
  const [imageName, setImageName] = useState(!!file.name ? file.name : '');
  const [status, setStatus] = useState(
    !!eventToEdit.status ? eventToEdit.status : 0
  );
  const [errors, setErrors] = useState([]);
  const [isPictureDefaultLogo, setIsPictureDefaultLogo] = useState(
    Object.keys(eventToEdit).length && !!eventToEdit.picture
      ? eventToEdit.picture.endsWith('default_tpbj_logo.png')
      : false
  );

  const currentDate = new Date().toISOString();

  const [startTime, setStartTime] = useState(
    eventToEdit.start !== '' ? eventToEdit.start : currentDate
  );
  const [endTime, setEndTime] = useState(
    eventToEdit.end !== '' ? eventToEdit.end : currentDate
  );

  const checkoutSchema = yup.object().shape({
    title: yup.string().required('required'),
    content: yup.string().required('required'),
    start: yup.string().required('required'),
    end: yup.string().required('required'),
    status: yup.string().required('required'),
  });

  const initialValues = {
    title: !!eventToEdit.title ? eventToEdit.title : '',
    subtitle: !!eventToEdit.subtitle ? eventToEdit.subtitle : '',
    content: !!eventToEdit.content ? eventToEdit.content : '',
    url: !!eventToEdit.url ? eventToEdit.url : '',
    url_name: !!eventToEdit.url_name ? eventToEdit.url_name : '',
    start: !!eventToEdit.start ? eventToEdit.start : '',
    end: !!eventToEdit.end ? eventToEdit.end : '',
    status: !!eventToEdit.status ? eventToEdit.status : '',
    picture: !!eventToEdit.picture ? eventToEdit.picture : '',
    location: !!eventToEdit.location ? eventToEdit.location : '',
  };

  const onChangeImg = (event) => {
    setImageName(event.target.files[0]['name']);
    setName(URL.createObjectURL(event.target.files[0]));
    setFile(event.target.files[0]);
    setIsPictureDefaultLogo(false);
  };

  const removePhoto = () => {
    eventToEdit.picture = '';
    setImageName('');
    setName('');
    setFile({});
  };

  const disableDeleteImageButton = () => {
    let isButtonDisabled = true;

    if (Object.keys(eventToEdit).length) {
      if (eventToEdit.picture !== '') {
        isButtonDisabled = false;
      }
    }

    if (Object.keys(file).length || imageName !== '' || name !== '') {
      isButtonDisabled = false;
    }

    return isButtonDisabled;
  };

  useEffect(() => {
    if (errors.length > 0) {
      let sviErrori = errors.join(', ');

      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: defaultLanguage['login-form-input-validation'],
        html: sviErrori,
        showConfirmButton: false,
        timer: 4000,
      });
    }
  }, [errors, defaultLanguage]);

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };

  const handleFormSubmit = (values) => {
    console.log(values);
  };

  const handleSubmitForm = async (values) => {
    values.preventDefault();

    const valuesApproved = checkFormIntegrity(values.target);

    if (valuesApproved.formErrors.length === 0) {
      if (name !== '') {
        const responseUpload = await UploadImage(file);

        if (responseUpload.data.status === 200) {
          valuesApproved.allInputs.picture = file.name;
        }
      } else {
        valuesApproved.allInputs.picture = '';
      }

      valuesApproved.allInputs.status = status;
      valuesApproved.allInputs.start = startTime;
      valuesApproved.allInputs.end = endTime;

      const response = await AddNewEvent(
        JSON.stringify(valuesApproved.allInputs)
      );

      if (response.status === 200) {
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: defaultLanguage['event-success-add'],
          html: response.info,
          showConfirmButton: false,
          timer: 1500,
        });

        navigate('/events');
      } else {
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: defaultLanguage['event-email-exist'],
          html: response.info,
          showConfirmButton: false,
          timer: 4000,
        });
      }
    } else {
      return setErrors(valuesApproved.formErrors);
    }
  };

  const handleEditForm = async (eventValues) => {
    const valuesApproved = checkFormIntegrity(eventValues, true);

    if (valuesApproved.formErrors.length === 0) {
      if (!!valuesApproved['allInputs'].picture || imageName) {
        const responseUpload = await UploadImage(file, eventToEdit['id']);

        if (responseUpload.status === 200) {
          valuesApproved['allInputs'].picture = imageName;
        }
      } else if (eventToEdit.picture) {
        console.log(eventToEdit.picture);
      } else {
        valuesApproved['allInputs'].picture = '';
      }

      valuesApproved['allInputs'].id = eventToEdit.id;
      valuesApproved['allInputs'].status = status;
      valuesApproved['allInputs'].start = startTime;
      valuesApproved['allInputs'].end = endTime;

      const responseEdit = await EditEvent(valuesApproved['allInputs']);

      if (responseEdit.status === 200) {
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: defaultLanguage['event-success-edit'],
          html: responseEdit.data.info,
          showConfirmButton: false,
          timer: 1500,
        });

        navigate('/events');
      }
    } else {
      return setErrors(valuesApproved.formErrors);
    }
  };

  const checkFormIntegrity = (values, isEdit = false) => {
    let formErrors = [];

    let allInputs = {};

    if (isEdit) {
      Object.keys(values).forEach((item) => {
        const singleItem = values[item];

        switch (item) {
          case 'title':
            if (singleItem === '') {
              formErrors.push(defaultLanguage['general-form-fields-title']);
            } else {
              allInputs.title = singleItem;
            }
            break;

          case 'subtitle':
            allInputs.subtitle = singleItem;
            break;

          case 'content':
            if (singleItem === '') {
              formErrors.push(defaultLanguage['general-form-fields-content']);
            } else {
              allInputs.content = singleItem;
            }
            break;

          case 'url':
            allInputs.url = singleItem;
            break;

          case 'url_name':
            allInputs.url_name = singleItem;
            break;

          case 'location':
            allInputs.location = singleItem;
            break;

          default:
            break;
        }
      });
    } else {
      Object.keys(values).forEach((item) => {
        const singleItem = values[item];

        if (
          singleItem.tagName === 'INPUT' ||
          singleItem.tagName === 'TEXTAREA'
        ) {
          switch (singleItem.name) {
            case 'title':
              if (singleItem.defaultValue === '') {
                formErrors.push(defaultLanguage['general-form-fields-title']);
              } else {
                allInputs.title = singleItem.defaultValue;
              }

              break;

            case 'subtitle':
              if (singleItem.defaultValue !== '') {
                allInputs.subtitle = singleItem.defaultValue;
              }
              break;

            case 'content':
              if (singleItem.defaultValue === '') {
                formErrors.push(defaultLanguage['general-form-fields-content']);
              } else {
                allInputs.content = singleItem.defaultValue;
              }

              break;

            case 'url':
              if (singleItem.defaultValue !== '') {
                allInputs.url = singleItem.defaultValue;
              }
              break;

            case 'url_name':
              if (singleItem.defaultValue !== '') {
                allInputs.url_name = singleItem.defaultValue;
              }
              break;

            case 'location':
              if (singleItem.defaultValue !== '') {
                allInputs.location = singleItem.defaultValue;
              }

              break;

            default:
              break;
          }
        }
      });
    }

    if (!startTime) {
      formErrors.push(defaultLanguage['general-form-fields-start']);
    }

    if (!endTime) {
      formErrors.push(defaultLanguage['general-form-fields-end']);
    }
    return { formErrors, allInputs };
  };

  const headerForm =
    Object.keys(eventToEdit).length === 0 ? (
      <Header
        title={defaultLanguage['event-form-new-title']}
        subtitle={defaultLanguage['event-form-new-subtitle']}
      />
    ) : (
      <Header
        title={defaultLanguage['event-form-edit-title']}
        subtitle={defaultLanguage['event-form-edit-subtitle']}
      />
    );

  return (
    <>
      <div style={{ textAlign: 'center' }}>{headerForm}</div>
      <Box
        display='flex'
        justifyContent='center'
        style={{ paddingBottom: '5rem' }}
      >
        <Box width='75%'>
          <Formik
            onSubmit={handleFormSubmit}
            initialValues={initialValues}
            validationSchema={checkoutSchema}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
            }) => (
              <form onSubmit={handleSubmitForm}>
                <Box display='flex' flexDirection='column' gap='20px'>
                  <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <div className='date-time-picker'>
                      <h2>{defaultLanguage['events-table-start']}</h2>
                      <DateAndTimePicker
                        getDateTime={setStartTime}
                        defaultValue={
                          !!eventToEdit.start ? eventToEdit.start : null
                        }
                      />
                    </div>

                    <div className='date-time-picker'>
                      <h2>{defaultLanguage['events-table-end']}</h2>
                      <DateAndTimePicker
                        getDateTime={setEndTime}
                        defaultValue={
                          !!eventToEdit.end ? eventToEdit.end : null
                        }
                      />
                    </div>
                  </div>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='text'
                    label={defaultLanguage['events-table-title']}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.title}
                    name='title'
                    error={!!touched.title && !!errors.title}
                    helperText={defaultLanguage['events-table-title-helper']}
                  />
                  <TextField
                    fullWidth
                    variant='filled'
                    type='text'
                    label={defaultLanguage['events-table-subtitle']}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.subtitle}
                    name='subtitle'
                    error={!!touched.subtitle && !!errors.subtitle}
                    helperText={defaultLanguage['events-table-subtitle-helper']}
                  />
                  <TextField
                    fullWidth
                    variant='filled'
                    type='text'
                    label={defaultLanguage['events-table-content']}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.content}
                    name='content'
                    error={!!touched.content && !!errors.content}
                    helperText={defaultLanguage['events-table-content-helper']}
                  />
                  <TextField
                    fullWidth
                    variant='filled'
                    type='text'
                    label={defaultLanguage['events-table-url']}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.url}
                    name='url'
                    error={!!touched.url && !!errors.url}
                    helperText={defaultLanguage['events-table-url-helper']}
                  />
                  <TextField
                    fullWidth
                    variant='filled'
                    type='text'
                    label={defaultLanguage['events-table-url-name']}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.url_name}
                    name='url_name'
                    error={!!touched.url_name && !!errors.url_name}
                    helperText={defaultLanguage['events-table-url-name-helper']}
                  />
                  <TextField
                    fullWidth
                    variant='filled'
                    type='text'
                    label={defaultLanguage['events-table-location']}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.location}
                    name='location'
                    error={!!touched.location && !!errors.location}
                    helperText={defaultLanguage['events-table-location-helper']}
                  />

                  <TextField
                    select
                    label='Status'
                    name='status'
                    helperText={defaultLanguage['events-table-status-helper']}
                    variant='filled'
                    value={status}
                    onChange={handleStatusChange}
                  >
                    <MenuItem key={1} value={0}>
                      {defaultLanguage['post-form-status-draft']}
                    </MenuItem>
                    <MenuItem key={2} value={1}>
                      {defaultLanguage['post-form-status-published']}
                    </MenuItem>
                  </TextField>

                  <h2>Vodeća fotografija</h2>
                  {name || eventToEdit.picture ? (
                    <Box display='flex' justifyContent='center'>
                      <Box
                        style={{
                          padding: '.5rem',
                          border: isPictureDefaultLogo ? null : '1px solid',
                        }}
                      >
                        <ImageListItem>
                          <img
                            src={
                              name
                                ? name
                                : eventToEdit.picture
                                ? `${eventToEdit.picture}`
                                : ''
                            }
                            alt={`${eventToEdit.picture || imageName}`}
                            loading='lazy'
                            style={{
                              width: isPictureDefaultLogo ? '200px' : null,
                            }}
                          />
                        </ImageListItem>
                      </Box>
                    </Box>
                  ) : null}

                  <Box
                    display='flex'
                    justifyContent={'space-between'}
                    gap='10px'
                    alignItems='center'
                  >
                    <Button
                      variant='contained'
                      component='label'
                      color='secondary'
                    >
                      {defaultLanguage['post-form-button-select-image']}
                      <input
                        accept='image/*'
                        type='file'
                        onChange={onChangeImg}
                        hidden
                      />
                    </Button>
                    <Button
                      variant='contained'
                      component='label'
                      color='error'
                      onClick={removePhoto}
                      style={{ wordBreak: 'break-all' }}
                      disabled={disableDeleteImageButton()}
                    >
                      {defaultLanguage['post-form-button-delete-image']}
                    </Button>
                  </Box>

                  {Object.keys(eventToEdit).length === 0 ? (
                    <Button type='submit' color='secondary' variant='contained'>
                      {defaultLanguage['events-form-button-create']}
                    </Button>
                  ) : (
                    <Button
                      onClick={() => handleEditForm(values)}
                      color='secondary'
                      variant='contained'
                    >
                      {defaultLanguage['events-form-button-update']}
                    </Button>
                  )}
                </Box>
              </form>
            )}
          </Formik>
        </Box>
      </Box>
    </>
  );
};

export default EventsForm;
