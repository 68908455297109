import React from 'react';
import axios from 'axios';

const targetAPI = process.env.REACT_APP_API_URL;

export const GetEvents = async () => {
  return await axios.get(`${targetAPI}/events.php`);
};

export const AddNewEvent = async (allInputs) => {
  return await axios
    .post(`${targetAPI}/events.php`, allInputs, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

export const DeleteEvents = async (props) => {
  return axios
    .delete(`${targetAPI}/events.php`, {
      data: {
        id: props,
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
    });
};

export const EditEvent = async (valuesApproved) => {
  return await axios
    .put(`${targetAPI}/events.php`, valuesApproved)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
    });
};

export const UploadImage = async (file, eventId) => {
  const formData = new FormData();
  formData.append('image', file);
  formData.append('event_id', eventId);
  let url = `${targetAPI}/config/uploadImage.php`;

  return await axios
    .post(url, formData)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(error);
    });
};
