import { Box, useTheme, Button } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { tokens } from '../../theme';
import LanguageContext from '../../LanguageContext';
import AdministratorContext from '../../AdministratorContext';
import Header from '../../components/Header';
import { useState, useEffect, useContext } from 'react';
import {
  GetCategories,
  DeleteCategory,
} from '../../services/CategoriesService';
import { useNavigate } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import Swal from 'sweetalert2';
import english from '../../locales/english.json';
import croatian from '../../locales/croatian.json';

const Categories = () => {
  const navigate = useNavigate();
  const isNonMobile = useMediaQuery('(min-width:600px)');
  const { language } = useContext(LanguageContext);
  const defaultLanguage = language === 0 ? croatian : english;
  const { administratorInfo } = useContext(AdministratorContext);

  const [categories, setCategories] = useState([]);
  const [isEditDisabled, setIsEditDisabled] = useState(true);
  const [isDeleteDisabled, setIsDeleteDisabled] = useState(true);
  const [selectedRow, setSelectedRow] = useState([]);
  const [isDeleted, setIsDeleted] = useState(false);
  const [categoryForEdit, setCategoryForEdit] = useState({});

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const columns = [
    { field: 'id', headerName: 'ID' },
    {
      field: 'name',
      headerName: defaultLanguage['categories-table-name'],
      flex: 1,
    },
    {
      field: 'post_count',
      headerName: defaultLanguage['categories-table-times-used'],
      flex: 1,
    },
    {
      field: 'date_created',
      headerName: defaultLanguage['categories-table-date-created'],
      flex: 1,
    },
    {
      field: 'date_updated',
      headerName: defaultLanguage['categories-table-date-updated'],
      flex: 1,
    },
  ];

  const findCategoriesQuery = () => {
    const findCategories = async () => {
      const response = await GetCategories();

      if (response.data.status === 404) {
        setCategories([]);
      } else {
        const categories = response.data.categories.map((item) => {
          item['id'] = parseInt(item['id']);
          return item;
        });

        setCategories(categories);
      }
    };
    findCategories();
  };

  useEffect(() => {
    findCategoriesQuery();
  }, []);

  useEffect(() => {
    if (isDeleted) {
      findCategoriesQuery();
      setIsDeleted(false);
    }
  }, [isDeleted]);

  const checkButtons = (data) => {
    if (
      administratorInfo.role === 'user' &&
      data.some((number) => number <= 3)
    ) {
      setIsEditDisabled(true);
      setIsDeleteDisabled(true);

      return;
    }

    setSelectedRow(data);

    let isEditBtnDisabled = false;
    let isDeleteBtnDisabled = false;

    if (data.length === 1) {
      isEditBtnDisabled = false;
    } else {
      isEditBtnDisabled = true;
    }

    if (data.length > 0) {
      isDeleteBtnDisabled = false;
    } else {
      isDeleteBtnDisabled = true;
    }

    if (!isEditBtnDisabled) {
      collectCategoryDataForEdit(data);
    }

    setIsEditDisabled(isEditBtnDisabled);
    setIsDeleteDisabled(isDeleteBtnDisabled);
  };

  const collectCategoryDataForEdit = (rowId) => {
    const rowIdTest = parseInt(rowId[0]);

    const selectedCategory = categories.find(
      (category) => category.id === rowIdTest
    );

    setCategoryForEdit(selectedCategory);
  };

  const DeleteCategoryQuery = async () => {
    await Swal.fire({
      title: defaultLanguage['delete-categories-title'],
      text: defaultLanguage['delete-categories-text'],
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: defaultLanguage['confirm-button-text'],
      cancelButtonText: defaultLanguage['cancel-button-text'],
    }).then(async (result) => {
      if (result.isConfirmed) {
        const responseDelete = await DeleteCategory(selectedRow);

        if (responseDelete.data.status === 200) {
          setIsDeleted(true);
          Swal.fire(defaultLanguage['categories-success-delete']);
        } else if (responseDelete.data.status === 400) {
          Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: responseDelete.data.error,
            html: responseDelete.data.error,
            showConfirmButton: false,
            timer: 4000,
          });
        }
      }
    });
  };

  return (
    <Box m='20px'>
      <Header
        title={defaultLanguage['categories-title']}
        subtitle={defaultLanguage['categories-subtitle']}
      />

      <div
        style={
          !isNonMobile ? { display: 'flex', flexDirection: 'column' } : null
        }
      >
        <Button
          onClick={() => navigate('/category-form')}
          variant='contained'
          color='secondary'
        >
          {defaultLanguage['categories-button-add']}
        </Button>
        <Button
          onClick={() => navigate('/category-form', { state: categoryForEdit })}
          variant='contained'
          color='warning'
          disabled={isEditDisabled}
          style={isNonMobile ? { margin: '0 .5rem' } : { margin: '.5rem 0' }}
        >
          {defaultLanguage['categories-button-edit']}
        </Button>
        <Button
          onClick={() => DeleteCategoryQuery()}
          variant='contained'
          color='error'
          disabled={isDeleteDisabled}
        >
          {defaultLanguage['categories-button-delete']}
        </Button>
      </div>

      <Box
        m='40px 0 0 0'
        height='75vh'
        sx={{
          '& .MuiDataGrid-root': {
            border: 'none',
          },
          '& .MuiDataGrid-cell': {
            borderBottom: 'none',
          },
          '& .name-column--cell': {
            color: colors.greenAccent[300],
          },
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: colors.blueAccent[700],
            borderBottom: 'none',
          },
          '& .MuiDataGrid-virtualScroller': {
            backgroundColor: colors.primary[400],
          },
          '& .MuiDataGrid-footerContainer': {
            borderTop: 'none',
            backgroundColor: colors.blueAccent[700],
          },
          '& .MuiCheckbox-root': {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <DataGrid
          onSelectionModelChange={checkButtons}
          checkboxSelection
          rows={categories.status === 404 ? [] : categories}
          columns={columns}
        />
      </Box>
    </Box>
  );
};

export default Categories;
