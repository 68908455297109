import { Box, Typography, useTheme, Avatar, Button } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { tokens } from '../../theme';
import LanguageContext from '../../LanguageContext';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import Header from '../../components/Header';
import { useState, useEffect, useContext } from 'react';
import {
  GetAdministrators,
  DeleteAdministrator,
} from '../../services/AdministratorService';
import { useNavigate } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import Swal from 'sweetalert2';
import english from '../../locales/english.json';
import croatian from '../../locales/croatian.json';

const Administrators = () => {
  const navigate = useNavigate();
  const isNonMobile = useMediaQuery('(min-width:600px)');
  const { language } = useContext(LanguageContext);
  const defaultLanguage = language === 0 ? croatian : english;

  const [administrators, setAdministrators] = useState([]);
  const [isEditDisabled, setIsEditDisabled] = useState(true);
  const [isDeleteDisabled, setIsDeleteDisabled] = useState(true);
  const [selectedRow, setSelectedRow] = useState([]);
  const [isDeleted, setIsDeleted] = useState(false);
  const [administratorForEdit, setAdministratorForEdit] = useState(null);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const columns = [
    { field: 'id', headerName: 'ID' },
    {
      field: 'first_name',
      headerName: defaultLanguage['administrators-table-first-name'],
      flex: 1,
      cellClassName: 'name-column--cell',
    },
    {
      field: 'last_name',
      headerName: defaultLanguage['administrators-table-last-name'],
      flex: 1,
      cellClassName: 'name-column--cell',
    },
    {
      field: 'email',
      headerName: defaultLanguage['administrators-table-email'],
      flex: 1,
    },
    {
      field: 'customer',
      headerName: defaultLanguage['administrators-table-customer'],
      flex: 1,
    },
    {
      field: 'date_created',
      headerName: defaultLanguage['administrators-table-date-created'],
      flex: 1,
    },
    {
      field: 'date_updated',
      headerName: defaultLanguage['administrators-table-date-updated'],
      flex: 1,
    },
    {
      field: 'picture',
      headerName: defaultLanguage['administrators-table-picture'],
      headerAlign: 'center',
      renderCell: ({ row: { picture } }) => {
        return (
          <>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
              }}
            >
              <Avatar src={`${picture}`} />
            </div>
          </>
        );
      },
    },
    {
      field: 'role',
      headerName: defaultLanguage['administrators-table-role'],
      headerAlign: 'center',
      flex: 1,
      renderCell: ({ row: { role } }) => {
        return (
          <Box
            width='100%'
            m='0 auto'
            p='5px'
            display='flex'
            justifyContent='center'
            backgroundColor={
              role === 'administrator'
                ? colors.greenAccent[600]
                : role === 'user'
                ? colors.greenAccent[800]
                : colors.greenAccent[700]
            }
            borderRadius='4px'
          >
            {role === 'administrator' && <AdminPanelSettingsOutlinedIcon />}
            {/* {role === "moderator" && <SecurityOutlinedIcon />} */}
            {role === 'user' && <LockOpenOutlinedIcon />}
            <Typography color={colors.grey[100]} sx={{ ml: '5px' }}>
              {role}
            </Typography>
          </Box>
        );
      },
    },
  ];

  const findAdministratorsQuery = () => {
    const findAdministrators = async () => {
      const response = await GetAdministrators();

      if (response.data.status === 404) {
        setAdministrators([]);
      } else {
        const administrators = response.data.administrators.map((item) => {
          item['id'] = parseInt(item['id']);
          item['customer_id'] = parseInt(item['customer_id']);
          return item;
        });

        setAdministrators(administrators);
      }
    };
    findAdministrators();
  };

  useEffect(() => {
    findAdministratorsQuery();
  }, []);

  useEffect(() => {
    if (isDeleted) {
      findAdministratorsQuery();
      setIsDeleted(false);
    }
  }, [isDeleted]);

  const checkButtons = (data) => {
    setSelectedRow(data);

    let isEditBtnDisabled = false;
    let isDeleteBtnDisabled = false;

    if (data.length === 1) {
      isEditBtnDisabled = false;
    } else {
      isEditBtnDisabled = true;
    }

    if (data.length > 0) {
      isDeleteBtnDisabled = false;
    } else {
      isDeleteBtnDisabled = true;
    }

    if (!isEditBtnDisabled) {
      collectAdministratorDataForEdit(data);
    }

    setIsEditDisabled(isEditBtnDisabled);
    setIsDeleteDisabled(isDeleteBtnDisabled);
  };

  const collectAdministratorDataForEdit = (rowId) => {
    const rowIdTest = parseInt(rowId[0]);

    const selectedAdministrator = administrators.find(
      (administrator) => parseInt(administrator.id) === rowIdTest
    );

    setAdministratorForEdit(selectedAdministrator);
  };

  const DeleteAdministratorQuery = async (props) => {
    await Swal.fire({
      title: defaultLanguage['delete-administrator-title'],
      text: defaultLanguage['delete-administrator-text'],
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: defaultLanguage['confirm-button-text'],
      cancelButtonText: defaultLanguage['cancel-button-text'],
    }).then(async (result) => {
      if (result.isConfirmed) {
        const responseDelete = await DeleteAdministrator(props);

        if (responseDelete.status === 200) {
          setIsDeleted(true);
          Swal.fire(defaultLanguage['administrator-success-delete']);
        }
      }
    });
  };

  return (
    <Box m='20px'>
      <Header
        title={defaultLanguage['administrators-title']}
        subtitle={defaultLanguage['administrators-subtitle']}
      />

      <div
        style={
          !isNonMobile ? { display: 'flex', flexDirection: 'column' } : null
        }
      >
        <Button
          onClick={() => navigate('/administrator-form')}
          variant='contained'
          color='secondary'
        >
          {defaultLanguage['administrators-button-add']}
        </Button>
        <Button
          onClick={() =>
            navigate('/administrator-form', { state: administratorForEdit })
          }
          variant='contained'
          color='warning'
          disabled={isEditDisabled}
          style={isNonMobile ? { margin: '0 .5rem' } : { margin: '.5rem 0' }}
        >
          {defaultLanguage['administrators-button-edit']}
        </Button>
        <Button
          onClick={() => DeleteAdministratorQuery(selectedRow)}
          variant='contained'
          color='error'
          disabled={isDeleteDisabled}
        >
          {defaultLanguage['administrators-button-delete']}
        </Button>
      </div>

      <Box
        m='40px 0 0 0'
        height='75vh'
        sx={{
          '& .MuiDataGrid-root': {
            border: 'none',
          },
          '& .MuiDataGrid-cell': {
            borderBottom: 'none',
          },
          '& .name-column--cell': {
            color: colors.greenAccent[300],
          },
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: colors.blueAccent[700],
            borderBottom: 'none',
          },
          '& .MuiDataGrid-virtualScroller': {
            backgroundColor: colors.primary[400],
          },
          '& .MuiDataGrid-footerContainer': {
            borderTop: 'none',
            backgroundColor: colors.blueAccent[700],
          },
          '& .MuiCheckbox-root': {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <DataGrid
          onSelectionModelChange={checkButtons}
          checkboxSelection
          rows={administrators.status === 404 ? [] : administrators}
          columns={columns}
        />
      </Box>
    </Box>
  );
};

export default Administrators;
